import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function ManualsModal({ onClose }) {
    const [categoryTitle, setCategoryTitle] = useState('');
    const [subcategoryTitle, setSubcategoryTitle] = useState('');
    const [subSubcategoryTitle, setSubSubcategoryTitle] = useState('');
    const [manualTitle, setManualTitle] = useState('');
    const [manualContent, setManualContent] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);
    const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null); // Track selected sub-subcategory
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [subSubcategories, setSubSubcategories] = useState([]);
    const [manuals, setManuals] = useState([]);
    const [view, setView] = useState('createManual'); // To handle different views
    const [selectedManual, setSelectedManual] = useState(null); // Track selected manual for edit
    const [searchTerm, setSearchTerm] = useState(''); // Track search term
    const [editingCategoryId, setEditingCategoryId] = useState(null);
    const [editCategoryTitle, setEditCategoryTitle] = useState('');



    useEffect(() => {
        fetchCategories();
    }, []);

    // Fetch categories from the backend
    const fetchCategories = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`);
            const data = await response.json();
            setCategories(data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

       // Fetch subcategories when a category is selected
    const handleCategoryChange = async (categoryId) => {
        const selectedCategory = categories.find(cat => cat.id === Number(categoryId));
        setSelectedCategory(selectedCategory);
        setSelectedSubcategory(null);
        setSelectedSubSubcategory(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`);
            const data = await response.json();
            setSubcategories(data);
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };


    // Fetch sub-subcategories when a subcategory is selected
    const handleSubcategoryChange = async (subcategoryId) => {
        const selectedSubcategory = subcategories.find(sub => sub.id === Number(subcategoryId));
        setSelectedSubcategory(selectedSubcategory);
        setSelectedSubSubcategory(null);
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}/sub-subcategories`);
            const data = await response.json();
            setSubSubcategories(data);
        } catch (error) {
            console.error('Error fetching sub-subcategories:', error);
        }
    };

    // Fetch manuals when a sub-subcategory is selected
    const handleSubSubcategoryChange = async (subSubcategoryId) => {
        const selectedSubSubcategory = subSubcategories.find(subSub => subSub.id === Number(subSubcategoryId));
        setSelectedSubSubcategory(selectedSubSubcategory);
        fetchManuals(subSubcategoryId);
    };

    const fetchManuals = async (subcategoryId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/${subcategoryId}`);
            const data = await response.json();
            setManuals(data);
        } catch (error) {
            console.error('Error fetching manuals:', error);
        }
    };

    // Create Manual
    const handleCreateManual = async () => {
        if (!selectedSubSubcategory || manualTitle.trim() === '' || manualContent.trim() === '') return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    subcategoryId: selectedSubSubcategory.id, // Using sub-subcategory ID
                    title: manualTitle,
                    content: manualContent
                }),
            });
            if (response.ok) {
                setManualTitle('');
                setManualContent('');
                handleSubSubcategoryChange(selectedSubSubcategory.id); // Refresh manuals
            } else {
                console.error('Failed to create manual');
            }
        } catch (error) {
            console.error('Error creating manual:', error);
        }
    };

    // Delete a manual
    const handleDeleteManual = async (manualId) => {
        if (!window.confirm('Are you sure you want to delete this manual?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/${manualId}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                handleSubcategoryChange(selectedSubcategory.id); // Refresh manuals
            } else {
                console.error('Failed to delete manual');
            }
        } catch (error) {
            console.error('Error deleting manual:', error);
        }
    };

    // Create a new category
    const handleCreateCategory = async () => {
        if (categoryTitle.trim() === '') return;
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title: categoryTitle }),
            });
            if (response.ok) {
                setCategoryTitle('');
                fetchCategories();
            } else {
                console.error('Failed to create category');
            }
        } catch (error) {
            console.error('Error creating category:', error);
        }
    };

    // Create a new subcategory
    const handleCreateSubcategory = async () => {
        if (!selectedCategory || subcategoryTitle.trim() === '') return;
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ categoryId: selectedCategory.id, title: subcategoryTitle }),
            });
            if (response.ok) {
                setSubcategoryTitle('');
                handleCategoryChange(selectedCategory.id); // Refresh subcategories
            } else {
                console.error('Failed to create subcategory');
            }
        } catch (error) {
            console.error('Error creating subcategory:', error);
        }
    };


// Create a new sub-subcategory
const handleCreateSubSubcategory = async () => {
    if (!selectedSubcategory || subSubcategoryTitle.trim() === '') return;
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ subcategoryId: selectedSubcategory.id, title: subSubcategoryTitle }),
        });
        if (response.ok) {
            setSubSubcategoryTitle('');
            handleSubcategoryChange(selectedSubcategory.id); // Refresh sub-subcategories
        } else {
            console.error('Failed to create sub-subcategory');
        }
    } catch (error) {
        console.error('Error creating sub-subcategory:', error);
    }
};

// Delete subcategory
const handleDeleteSubcategory = async (subcategoryId) => {
    if (!window.confirm('Are you sure you want to delete this subcategory?')) return;
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}`, {
            method: 'DELETE',
        });
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Failed to delete subcategory:', errorText);
        } else {
            console.log('Subcategory deleted successfully');
            handleCategoryChange(selectedCategory.id); // Refresh subcategories
        }
    } catch (error) {
        console.error('Error deleting subcategory:', error);
    }
};


 // Delete sub-subcategory
 const handleDeleteSubSubcategory = async (subSubcategoryId) => {
    if (!window.confirm('Are you sure you want to delete this sub-subcategory?')) return;
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategoryId}`, {
            method: 'DELETE',
        });
        if (response.ok) {
            handleSubcategoryChange(selectedSubcategory.id); // Refresh sub-subcategories
        } else {
            console.error('Failed to delete sub-subcategory');
        }
    } catch (error) {
        console.error('Error deleting sub-subcategory:', error);
    }
};

    // Handle Edit Manual
    const handleEditManual = (manual) => {
        setManualTitle(manual.title); // Populate title
        setManualContent(manual.content); // Populate content into the editor
        setSelectedManual(manual); // Mark it as the selected manual for editing
    };

    // Update the manual (after editing)
    const handleUpdateManual = async () => {
        if (!selectedManual) return; // Ensure a manual is selected

        if (!window.confirm('Are you sure you want to update this manual?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/manuals/${selectedManual.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    title: manualTitle,
                    content: manualContent
                })
            });

            if (response.ok) {
                setSelectedManual(null); // Clear selected manual after update
                setManualTitle(''); // Clear the manual title input
                setManualContent(''); // Clear the rich text editor content
                handleSubcategoryChange(selectedSubcategory.id); // Refresh the manuals list
            } else {
                console.error('Failed to update manual');
            }
        } catch (error) {
            console.error('Error updating manual:', error);
        }
    };

    // Function to start editing a category
    const handleEditCategory = (category) => {
        setEditingCategoryId(category.id); // Mark the category for editing
        setEditCategoryTitle(category.title); // Populate the edit input
    };

    // Function to update a category
    const handleUpdateCategory = async () => {
        if (!window.confirm('Are you sure you want to update this category?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${editingCategoryId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ title: editCategoryTitle })
            });

            if (response.ok) {
                setEditingCategoryId(null); // Clear edit mode
                fetchCategories(); // Refresh categories
            } else {
                console.error('Failed to update category');
            }
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    // Function to delete a category
    const handleDeleteCategory = async (categoryId) => {
        if (!window.confirm('Are you sure you want to delete this category?')) return;

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}`, {
                method: 'DELETE'
            });

            if (response.ok) {
                fetchCategories(); // Refresh categories
            } else {
                console.error('Failed to delete category');
            }
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    // Edit subcategory (enter edit mode)
const handleEditSubcategory = (subcategory) => {
    setEditingCategoryId(subcategory.id); // Mark subcategory as being edited
    setEditCategoryTitle(subcategory.title); // Pre-fill the input with current title
};

// Edit sub-subcategory (enter edit mode)
const handleEditSubSubcategory = (subSubcategory) => {
    setEditingCategoryId(subSubcategory.id); // Mark sub-subcategory as being edited
    setEditCategoryTitle(subSubcategory.title); // Pre-fill the input with current title
};

// Update subcategory
const handleUpdateSubcategory = async (subcategory) => {
    if (!editCategoryTitle.trim()) return;

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategory.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ title: editCategoryTitle }),
        });

        if (response.ok) {
            setEditingCategoryId(null); // Exit edit mode
            setEditCategoryTitle(''); // Clear the input
            handleCategoryChange(selectedCategory.id); // Refresh subcategories
        } else {
            console.error('Failed to update subcategory');
        }
    } catch (error) {
        console.error('Error updating subcategory:', error);
    }
};

// Update sub-subcategory
const handleUpdateSubSubcategory = async (subSubcategory) => {
    if (!editCategoryTitle.trim()) return;

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/oc/sub-subcategories/${subSubcategory.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ title: editCategoryTitle }),
        });

        if (response.ok) {
            setEditingCategoryId(null); // Exit edit mode
            setEditCategoryTitle(''); // Clear the input
            handleSubcategoryChange(selectedSubcategory.id); // Refresh sub-subcategories
        } else {
            console.error('Failed to update sub-subcategory');
        }
    } catch (error) {
        console.error('Error updating sub-subcategory:', error);
    }
};


return (
    <div className="manuals-modal-overlay">
        <div className="manuals-modal-content">
            <button className="manuals-modal-close-button" onClick={onClose}>X</button>

            <div className="manuals-modal-sidebar">
                <h3>Manage Manuals</h3>
                <button onClick={() => setView('manageCategories')}>Manage Categories</button>
                <button onClick={() => setView('manageManuals')}>Manage Manuals</button>
                <button onClick={() => setView('createManual')}>Create Manual</button>
            </div>

            {/* Main Content */}
            <div className="manuals-modal-main-content">
                {/* Manage Categories View */}
                {view === 'manageCategories' && (
                    <div>
                        <h4>Manage Categories & Subcategories</h4>

                        {/* Main Categories Section */}
                        <div>
                            <h4>Main Categories</h4>
                            {categories.length > 0 ? (
                                <ul className="manuals-modal-categories-list">
                                    {categories.map((category) => (
                                        <div key={category.id} className="manuals-modal-categories-list-item">
                                            <span>{category.title}</span>
                                            <div className="manuals-modal-buttons">
                                                <button onClick={() => handleEditCategory(category)}>Edit</button>
                                                <button onClick={() => handleDeleteCategory(category.id)}>Delete</button>
                                            </div>
                                        </div>
                                    ))}
                                </ul>
                            ) : (
                                <p>No categories found.</p>
                            )}

                            <h4>Create New Main Category</h4>
                            <input
                                type="text"
                                value={categoryTitle}
                                placeholder="Category title"
                                onChange={(e) => setCategoryTitle(e.target.value)}
                            />
                            <button onClick={handleCreateCategory}>Create Category</button>
                        </div>

                        {/* Subcategories Section */}
                        <div>
                            <h4>Subcategories</h4>
                            {subcategories.length > 0 ? (
                                <ul className="manuals-modal-categories-list">
                                    {subcategories.map((subcategory) => (
                                        <div key={subcategory.id} className="manuals-modal-categories-list-item">
                                            {editingCategoryId === subcategory.id ? (
                                                <>
                                                    <input
                                                        type="text"
                                                        value={editCategoryTitle}
                                                        onChange={(e) => setEditCategoryTitle(e.target.value)}
                                                    />
                                                    <div className="manuals-modal-buttons">
                                                        <button onClick={() => handleUpdateSubcategory(subcategory)}>Save</button>
                                                        <button onClick={() => setEditingCategoryId(null)}>Cancel</button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <span>{subcategory.title}</span>
                                                    <div className="manuals-modal-buttons">
                                                        <button onClick={() => handleEditSubcategory(subcategory)}>Edit</button>
                                                        <button onClick={() => handleDeleteSubcategory(subcategory.id)}>Delete</button>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </ul>
                            ) : (
                                <p>No subcategories available.</p>
                            )}

                            {/* Sub-subcategories Section */}
                            {selectedSubcategory && (
                                <div>
                                    <h4>Sub-subcategories</h4>
                                    {subSubcategories.length > 0 ? (
                                        <ul className="manuals-modal-categories-list">
                                            {subSubcategories.map((subSubcategory) => (
                                                <div key={subSubcategory.id} className="manuals-modal-categories-list-item">
                                                    {editingCategoryId === subSubcategory.id ? (
                                                        <>
                                                            <input
                                                                type="text"
                                                                value={editCategoryTitle}
                                                                onChange={(e) => setEditCategoryTitle(e.target.value)}
                                                            />
                                                            <div className="manuals-modal-buttons">
                                                                <button onClick={() => handleUpdateSubSubcategory(subSubcategory)}>Save</button>
                                                                <button onClick={() => setEditingCategoryId(null)}>Cancel</button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span>{subSubcategory.title}</span>
                                                            <div className="manuals-modal-buttons">
                                                                <button onClick={() => handleEditSubSubcategory(subSubcategory)}>Edit</button>
                                                                <button onClick={() => handleDeleteSubSubcategory(subSubcategory.id)}>Delete</button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No sub-subcategories available.</p>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* Manage Manuals View */}
                {view === 'manageManuals' && (
                    <div>
                        <h4>Manage Manuals</h4>

                        {/* Search Bar */}
                        <div>
                            <input
                                type="text"
                                placeholder="Search manuals..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="manuals-modal-search"
                            />
                        </div>

                        {/* Category Dropdown */}
                        <select
                            value={selectedCategory ? selectedCategory.id : ''}
                            onChange={(e) => handleCategoryChange(e.target.value)}
                        >
                            <option value="">Select Category</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.title}
                                </option>
                            ))}
                        </select>

                        {/* Subcategory Dropdown */}
                        <select
                            value={selectedSubcategory ? selectedSubcategory.id : ''}
                            onChange={(e) => handleSubcategoryChange(e.target.value)}
                            disabled={!selectedCategory}
                        >
                            <option value="">Select Subcategory</option>
                            {subcategories.map((subcategory) => (
                                <option key={subcategory.id} value={subcategory.id}>
                                    {subcategory.title}
                                </option>
                            ))}
                        </select>

                        {/* Sub-subcategory Dropdown */}
                        {subSubcategories.length > 0 && (
                            <select
                                value={selectedSubSubcategory ? selectedSubSubcategory.id : ''}
                                onChange={(e) => handleSubSubcategoryChange(e.target.value)}
                                disabled={!selectedSubcategory}
                            >
                                <option value="">Select Sub-subcategory</option>
                                {subSubcategories.map((subSubcategory) => (
                                    <option key={subSubcategory.id} value={subSubcategory.id}>
                                        {subSubcategory.title}
                                    </option>
                                ))}
                            </select>
                        )}

                        {/* List of Manuals */}
                        {manuals.length > 0 ? (
                            <div className="manuals-list">
                                {manuals
                                    .filter(manual => manual.title.toLowerCase().includes(searchTerm.toLowerCase()))
                                    .map((manual) => (
                                        <div key={manual.id} className="manuals-list-item">
                                            <span>{manual.title}</span>

                                            {/* Edit Button */}
                                            <button onClick={() => handleEditManual(manual)}>Edit</button>

                                            {/* Delete Button */}
                                            <button onClick={() => handleDeleteManual(manual.id)}>Delete</button>
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            <p>No manuals available for this subcategory.</p>
                        )}
                    </div>
                )}

                {/* Create Manual Section */}
                {view === 'createManual' && (
                    <div>
                        <h4>Create New Manual</h4>

                        {/* Category Dropdown */}
                        <select
                            value={selectedCategory ? selectedCategory.id : ''}
                            onChange={(e) => handleCategoryChange(e.target.value)}
                        >
                            <option value="">Select Category</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.title}
                                </option>
                            ))}
                        </select>

                        {/* Subcategory Dropdown */}
                        <select
                            value={selectedSubcategory ? selectedSubcategory.id : ''}
                            onChange={(e) => handleSubcategoryChange(e.target.value)}
                            disabled={!selectedCategory}
                        >
                            <option value="">Select Subcategory</option>
                            {subcategories.map((subcategory) => (
                                <option key={subcategory.id} value={subcategory.id}>
                                    {subcategory.title}
                                </option>
                            ))}
                        </select>

                        {/* Sub-subcategory Dropdown */}
                        <select
                            value={selectedSubSubcategory ? selectedSubSubcategory.id : ''}
                            onChange={(e) => handleSubSubcategoryChange(e.target.value)}
                            disabled={!selectedSubcategory}
                        >
                            <option value="">Select Sub-subcategory</option>
                            {subSubcategories.map((subSubcategory) => (
                                <option key={subSubcategory.id} value={subSubcategory.id}>
                                    {subSubcategory.title}
                                </option>
                            ))}
                        </select>

                        {/* Manual Title */}
                        <input
                            type="text"
                            value={manualTitle}
                            placeholder="Manual title"
                            onChange={(e) => setManualTitle(e.target.value)}
                        />

                        {/* Rich Text Editor for Manual Content */}
                        <ReactQuill
                            value={manualContent}
                            onChange={setManualContent}
                            placeholder="Write your manual content here..."
                            modules={{
                                toolbar: [
                                    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                    [{ size: [] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                    ['link', 'image'],
                                    ['clean'],
                                    [{ 'align': [] }]
                                ]
                            }}
                        />

                        {/* Create Manual Button */}
                        <button onClick={handleCreateManual} disabled={!selectedSubSubcategory}>
                            Create Manual
                        </button>
                    </div>
                )}
            </div>
        </div>
    </div>
);
}