import React, { useState, useEffect, useRef } from 'react';
import './Worktimes.css';
import Hourbank from './Hourbank';  // Importer den nye komponenten

export default function Ooh({ onClose, id }) {
    const [overrideDates, setOverrideDates] = useState([]);
    const [newDate, setNewDate] = useState('');
    const [weekData, setWeekData] = useState([]);
    const [currentWeek, setCurrentWeek] = useState(new Date().getWeek());
    const [vacationData, setVacationData] = useState([]);
    const [editedData, setEditedData] = useState({});
    const containerRef = useRef(null);

    useEffect(() => {
        fetchVacationData();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                saveAllChanges(); // Lagre når brukeren klikker utenfor ruten
                onClose(); // Lukk ruten
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const saveAllChanges = async () => {
        console.log('Saving all changes...');
        const savePromises = Object.keys(editedData).map(week =>
            handleSave(Number(week))
        );
        await Promise.all(savePromises);
        console.log('All changes saved.');
        fetchVacationData();
    };

    const fetchVacationData = async () => {
        try {
            console.log('Fetching vacation data...');
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/vacation`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            console.log('Vacation data fetched:', data);
    
            setVacationData(data);
            const initialEditedData = data.reduce((acc, { week, note, note2 }) => {
                acc[week] = { note: note || '', note2: note2 || '' };
                return acc;
            }, {});
            setEditedData(initialEditedData);
        } catch (err) {
            console.error('Error fetching vacation data:', err);
        }
    };
    
    
    

    const handleInputChange = (week, field, value) => {
        console.log(`Editing week: ${week}, field: ${field}, value: ${value}`);
        setEditedData(prev => ({
            ...prev,
            [week]: { ...prev[week], [field]: value }
        }));
    };
    
    

    const handleSave = async (week) => {
        const { note, note2 } = editedData[week] || {};
        console.log('Saving data for week:', week, { note, note2 });
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/vacation/${week}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ note, note2 })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            console.log('Save successful for week:', week);
            fetchVacationData();
        } catch (err) {
            console.error('Error saving data:', err);
        }
    };
    
    
    
    useEffect(() => {
    }, [id]);

    useEffect(() => {
        fetchOverrideDates();
        generateWeekData();
    }, [id]);
    
    const generateWeekData = () => {
        const schedule = [
            'Arnt', 'Jan', 'André', 'Anders', 'Arnt', 'Jan', 'Andre', 'Anders',
            'Arnt', 'Jan', 'Andre', 'Anders', 'Arnt', 'Jan', 'Andre', 'Anders',
            'Arnt', 'Jan', 'Andre', 'Anders', 'Arnt', 'Jan', 'Andre', 'Anders',
            'Arnt', 'Jan', 'Andre', 'Anders', 'Arnt', 'Jan', 'Andre', 'Anders',
            'Arnt', 'Jan', 'Andre', 'Anders', 'Arnt', 'Jan', 'Andre', 'Anders',
            'Arnt', 'Jan', 'Andre', 'Anders', 'Arnt', 'Jan', 'Andre', 'Anders'
        ];
        const data = schedule.map((name, index) => ({ week: index + 1, name }));
        setWeekData(data);
    };

    const fetchOverrideDates = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`);
            const dates = await response.json();
            setOverrideDates(dates.map(date => date.split('T')[0])); // Format date to YYYY-MM-DD
        } catch (error) {
            console.error('Error fetching override dates:', error);
        }
    };

    const handleDateChange = (e) => {
        setNewDate(e.target.value);
    };

    const handleDateSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ date: newDate })
            });
            if (response.ok) {
                fetchOverrideDates();
                setNewDate('');
            } else {
                console.error('Error adding override date');
            }
        } catch (error) {
            console.error('Error adding override date:', error);
        }
    };
    const handleDateDelete = async (date) => {
        try {
            const encodedDate = encodeURIComponent(date);
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates/${encodedDate}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                fetchOverrideDates();
            } else {
                const errorText = await response.text();
                console.error('Error deleting override date:', errorText);
            }
        } catch (error) {
            console.error('Error deleting override date:', error);
        }
    };


    const getFridayAndSundayDates = (weekNumber) => {
        const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
        const daysToAdd = (weekNumber - 1) * 7;
        const weekStart = new Date(firstDayOfYear.setDate(firstDayOfYear.getDate() - firstDayOfYear.getDay() + 1 + daysToAdd)); // Mandag i uke
        const friday = new Date(weekStart.setDate(weekStart.getDate() + 4)); // Fredag
        const sunday = new Date(friday.getTime());
        sunday.setDate(friday.getDate() + 2); // Søndag
        return `${friday.getDate()}-${sunday.getDate()} ${sunday.toLocaleString('default', { month: 'short' })}`;
    };
    

    return (
        <div className="ooh-background" onClick={onClose}>
            <div className="ooh-content" onClick={e => e.stopPropagation()}>

                <div className="three-columns-row">
                    {/* Første kolonne */}
                    <div className="unique-column-one">
                        <div className="override-dates-section">
                            <h3>Closed Dates</h3>
                            <ul>
                                {overrideDates.map((date, index) => (
                                    <li key={index}>
                                        {date}
                                        <button onClick={() => handleDateDelete(date)}>Delete</button>
                                    </li>
                                ))}
                            </ul>
                            <form onSubmit={handleDateSubmit}>
                                <label htmlFor="newDate">Add Closed Date:</label>
                                <input
                                    type="date"
                                    id="newDate"
                                    value={newDate}
                                    onChange={handleDateChange}
                                    required
                                />
                                <button type="submit">Add Date</button>
                            </form>
                        </div>
                    </div>
    
                    {/* Andre kolonne */}
                    <div className="unique-column-two">
                    <div className="current-week-section">
    <h3>Current Week</h3>
    <table>
        <thead>
            <tr>
                <th>Uke</th>
                <th>Dato</th>
                <th>Vakt</th>
                <th>Ferie</th>
                <th>Erstatter</th>
            </tr>
        </thead>
        <tbody>
            {weekData
                .filter(({ week }) => week >= currentWeek)
                .map(({ week, name, note, note2 }, index) => (
                    <tr key={index} className={week === currentWeek ? 'highlight' : ''}>
                        <td>{week}</td>
                        <td className="date-columndate">{getFridayAndSundayDates(week)}</td>
                        <td>{name}</td>
                        <td>
                        <td>
                            <input
                                type="text"
                                className="custom-input-note"
                                value={editedData[week]?.note || ''}
                                onChange={(e) => handleInputChange(week, 'note', e.target.value)}
                                onBlur={() => handleSave(week)} // Triggere lagring når fokus forlater feltet
                            />
                        </td>

                        </td>
                        <td>
                        <input
                            type="text"
                                className="custom-input-note"
                            value={editedData[week]?.note2 || ''}
                            onChange={(e) => handleInputChange(week, 'note2', e.target.value)}
                            onBlur={() => handleSave(week)} // Triggere lagring når fokus forlater feltet
                        />
                        </td>
                    </tr>
                ))}
        </tbody>
    </table>
</div>

                    </div>
    
                    {/* Tredje kolonne */}
                    <div className="unique-column-three">

{/* The "Time Bank" section */}
<Hourbank id={id}/>

</div>
                </div>
    
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
    
}

Date.prototype.getWeek = function () {
    const firstDay = new Date(this.getFullYear(), 0, 1);
    const dayOfYear = ((this - firstDay) / 86400000) + 1;
    return Math.ceil(dayOfYear / 7);
};
