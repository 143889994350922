import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { UserContext } from '../context/UserContext';


function Upload({ onClose }) {
  const { userProfile, setUserProfile } = useContext(UserContext);
  const { authToken } = useAuth();
  const [vehicleModels, setVehicleModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedVehicleId, setSelectedVehicleId] = useState(null); // To store the selected vehicle's ID
  const [transmissionType, setTransmissionType] = useState('');
  const [euroClass, seteuroClass] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Ny tilstand for å forhindre dobbeltrykk
  const [toolType, setToolType] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [tuningType, setTuningType] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [range, setRange] = useState('');
  const [fuel_type, setFuelType] = useState('');
  const [model, setModel] = useState('');
  const [type, setType] = useState('');
  const [file, setFile] = useState(null);
  const [gearboxFile, setGearboxFile] = useState(null);
  const [miscFile, setMiscFile] = useState(null);
  const [priority, setPriority] = useState(1);
  const [comments, setComments] = useState('');
  const [egr, setEgr] = useState(0);
  const [dpf, setDpf] = useState(0);
  const [urea, setUrea] = useState(0);
  const [decat, setDecat] = useState(0);
  const [epa, setEpa] = useState(0);
  const [hybridturbo, setHybridturbo] = useState(0);
  const [gearbox, setGearbox] = useState(0);
  const [nsl, setNsl] = useState(0);
  const [coldstart, setColdstart] = useState(0);
  const [startstop, setStartstop] = useState(0);
  const [popsbangs, setPopsbangs] = useState(0);
  const [burbles, setBurbles] = useState(0);
  const [swirlflaps, setSwirlflaps] = useState(0);
  const [tva, setTva] = useState(0);
  const [nonstock, setNonstock] = useState(0);
  const [showFirstConfirmation, setShowFirstConfirmation] = useState(false);
  const [showSecondConfirmation, setShowSecondConfirmation] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState(null);
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerNotes, setCustomerNotes] = useState('');
  const [vehicleError, setVehicleError] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(userProfile.did); // For overriding MID
  const [membersList, setMembersList] = useState([]); // For storing fetched members
  const [customModelName, setCustomModelName] = useState(''); // Nytt inputfelt for å angi manuelt modellnavn
  

  const [options, setOptions] = useState({
    egr: false,
    dpf: false,
    urea: false,
    decat: false,
    epa: false,
    hybridturbo: false,
    gearbox: false,
    nsl: false,
    coldstart: false,
    startstop: false,
    popsbangs: false,
    burbles: false,
    swirlflaps: false,
    tva: false,
    nonstock: false
  });

  const optionVisibilityMap = {
    egr: [1, 6, 8, 9, 10],
    dpf: [1, 6, 8, 9, 10],
    urea: [1, 6, 8, 9, 10],
    decat: [1, 10, 14],
    epa: [1],
    hybridturbo: [1],
    gearbox: [1],
    nsl: [1, 6, 8, 14],
    coldstart: [1],
    startstop: [1],
    popsbangs: [1, 14],
    burbles: [1, 14],
    swirlflaps: [1],
    tva: [1, 14],
    nonstock: [1, 6, 8, 9, 10, 14]
  };

  const sendNotificationToAdmin = async (title, body, data = {}) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}` // Use the authToken from useAuth()
        },
        body: JSON.stringify({ title, body, data })
      });
      if (!response.ok) {
        throw new Error('Failed to send notification to admin');
      }
      const result = await response.json();
      // console.log('Notification sent to admin:', result);
    } catch (error) {
      console.error('Error sending notification to admin:', error);
    }
  };

  const renderCheckbox = (optionName, label, cost) => {
    const shouldShow = selectedVehicle === 'Other Vehicle (Specify)' || optionVisibilityMap[optionName].includes(type);
    return shouldShow && (
      <label>
        <input
          type="checkbox"
          name={optionName}
          value="1"
          checked={options[optionName]}
          onChange={handleOptionChange}
        />
        {label}
        <br />
      </label>
    );
  };


  useEffect(() => {

    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserProfile(data);
          setIsAdmin(data.admin === 1 );
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, setUserProfile]);

  useEffect(() => {
    if (isAdmin) {
      const fetchMembersList = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/opt-members`, {
            headers: { Authorization: `Bearer ${authToken}` },
          });
          const data = await response.json();
          setMembersList(data);
        } catch (error) {
          console.error('Error fetching members list:', error);
        }
      };
      fetchMembersList();
    }
  }, [isAdmin, authToken]);

  useEffect(() => {
    fetch(process.env.REACT_APP_SITE_URL + '/vehicle-models')
      .then(response => response.json())
      .then(data => {
        setVehicleModels(data);
      })
      .catch(error => {
        console.error('Error fetching vehicle models:', error);
      });
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const handleFileOri = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleFileGearbox = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setGearboxFile(selectedFile);
    }
  };

  const handleFileMisc = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setMiscFile(selectedFile);
    }
  };

  const filteredModels = searchTerm
    ? vehicleModels.filter(model => {
      const fullModelString = `${model.manufacturerName} ${model.rangeName} ${model.modelName}`.toLowerCase();
      return searchTerm.toLowerCase().split(' ').every(part => fullModelString.includes(part));
    })
    : [];

    const suggestionsWithOtherOption = [...filteredModels, { id: 'other', manufacturerName: '', rangeName: '', modelName: 'Other Vehicle (Specify)' }];

    const handleSearch = (event) => {
      const value = event.target.value;
      setSearchTerm(value);
      setShowSuggestions(value.trim().length > 0 && suggestionsWithOtherOption.length > 0);
    };


    const selectVehicle = (model) => {
      if (model.id === 'other') {
        // Hvis "Other Vehicle (Specify)" er valgt
        setSelectedVehicle('Other Vehicle (Specify)');
        setSelectedVehicleId(null); // Nullstiller ID siden det er et egendefinert valg
        setManufacturer('');
        setRange('');
        setModel('');
        setType('');
        setFuelType('');
        setCustomModelName(''); // Nullstill det egendefinerte modellnavnet
      } else {
        setSelectedVehicle(`${model.manufacturerName} ${model.rangeName} ${model.modelName}`);
        setSelectedVehicleId(model.id);
        setManufacturer(model.manufacturerId);
        setRange(model.rangeId);
        setModel(model.id);
        setType(model.typeId);
        setFuelType(model.fueltype);
        setCustomModelName(''); // Nullstill det egendefinerte modellnavnet hvis det tidligere var satt
      }
      setSearchTerm('');
      setShowSuggestions(false);


    // Reset options when a new vehicle is selected
    setOptions({
        egr: false,
        dpf: false,
        urea: false,
        decat: false,
        epa: false,
        hybridturbo: false,
        gearbox: false,
        nsl: false,
        coldstart: false,
        startstop: false,
        popsbangs: false,
        burbles: false,
        swirlflaps: false,
        tva: false,
        nonstock: false
    });

    // Reset other states related to the selected vehicle, if needed
    setTuningType(''); // Reset the tuning type
    setTransmissionType(''); // Reset the transmission type if needed
    seteuroClass(''); // Reset the transmission type if needed
    // Add any other states you want to reset
};

const handleTypeChange = (event) => {
  setType(event.target.value);
  seteuroClass(''); // Reset Euroclass when changing type
};


  const handleCreateFileId = () => {
    const payload = {
      vehicleId: selectedVehicleId,
      transmissionType,
      toolType,
      fuel_type,
      tuningType,
      registrationNumber,
      priority,
      comments,
      egr,
      dpf,
      urea,
      decat,
      epa,
      hybridturbo,
      gearbox,
      nsl,
      coldstart,
      startstop,
      popsbangs,
      burbles,
      swirlflaps,
      tva,
      nonstock,
    };
    // Here you would send the payload to the server
    // fetch('http://localhost:5000/createFile', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(payload)});
  };

  const fetchVehicleInfo = async (regNum) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
      if (!response.ok) {
        throw new Error('Failed to fetch vehicle data');
      }
      const data = await response.json();
      if (data.kjoretoydataListe && data.kjoretoydataListe.length > 0) {
        setVehicleInfo(data);
        setVehicleError('');
      } else {
        setVehicleInfo(null);
        setVehicleError('Kunne ikke hente data for registreringsnummer, har du angitt riktig?');
      }
    } catch (error) {
      //console.error('Error fetching vehicle data:', error);
      setVehicleInfo(null);
      setVehicleError('Kunne ikke hente data for registreringsnummer, har du angitt riktig?');
    }
  };


  const handleSubmit = async () => {
    // Validation: Check that all required fields are filled out
    if (!selectedVehicleId && selectedVehicle !== 'Other Vehicle (Specify)') {
      alert('Please select a vehicle.');
      return;
    }

    if (!transmissionType) {
      alert('Please select a transmission type.');
      return;
    }

    if (!registrationNumber.trim()) {
      alert('Please enter a registration number.');
      return;
    }

    if (!toolType) {
      alert('Please select a tool type.');
      return;
    }

    if ([6, 8, 9].includes(type) && !euroClass) {
      alert('Please select a Euroclass.');
      return;
    }

    if (!tuningType) {  // Add this check for tuningType
      alert('Please select a tuning type.');
      return;
    }

    if (selectedVehicle === 'Other Vehicle (Specify)') {
      if (!type) {
        alert('Please select a type for "Other Vehicle".');
        return;
      }

      if (!customModelName.trim()) {
        alert('Please enter a model name for "Other Vehicle".');
        return;
      }

      // Sett manufacturer, range, og model til 0 for "Other Vehicle"
      setManufacturer(1);
      setRange(2);
      setModel(9999);
      setFuelType(9999); // Set fuel_type to 0 or any other default valid value
    }

    // If all checks pass, proceed with the existing logic
    if (registrationNumber && userProfile.country === 'NO') {
      await fetchVehicleInfo(registrationNumber);
    }
    setShowFirstConfirmation(true);
    
  };

  const finalManufacturer = manufacturer || 0;
  const finalRange = range || 0;
  const finalModel = model || 0;
  const finalFuelType = fuel_type || 0;

  const confirmFirstStep = () => {
    setShowFirstConfirmation(false);
    setShowSecondConfirmation(true);
  };

  const confirmSecondStep = async () => {
    if (isSubmitting) return; // Unngå at funksjonen kjører flere ganger
    setIsSubmitting(true); // Sett til true for å forhindre flere innsendelser
    const formData = new FormData();
    formData.append('username', userProfile.name);
    formData.append('mid', isAdmin ? selectedMemberId : userProfile.did); // Override if admin
    formData.append('v_type', type);
    formData.append('orifile', file);
    formData.append('gearboxfile', gearboxFile);
    formData.append('miscfile', miscFile);
    formData.append('manufacturer', finalManufacturer);
    formData.append('range', finalRange);
    formData.append('model', finalModel);
    formData.append('cmodel', selectedVehicle === 'Other Vehicle (Specify)' ? customModelName : '');
    formData.append('tuning_type', tuningType);
    formData.append('regnum', registrationNumber);
    formData.append('ttype', transmissionType);
    formData.append('tooltype', toolType);
    formData.append('fuel_type', finalFuelType);
    formData.append('euro_class', euroClass);  // Legg til euroClass
    formData.append('priority', priority);
    formData.append('comments', comments);
    formData.append('egr', options.egr ? 1 : 0);
    formData.append('dpf', options.dpf ? 1 : 0);
    formData.append('urea', options.urea ? 1 : 0);
    formData.append('decat', options.decat ? 1 : 0);
    formData.append('epa', options.epa ? 1 : 0);
    formData.append('hybridturbo', options.hybridturbo ? 1 : 0);
    formData.append('gearbox', options.gearbox ? 1 : 0);
    formData.append('nsl', options.nsl ? 1 : 0);
    formData.append('coldstart', options.coldstart ? 1 : 0);
    formData.append('startstop', options.startstop ? 1 : 0);
    formData.append('popsbangs', options.popsbangs ? 1 : 0);
    formData.append('burbles', options.burbles ? 1 : 0);
    formData.append('swirlflaps', options.swirlflaps ? 1 : 0);
    formData.append('tva', options.tva ? 1 : 0);
    formData.append('nonstock', options.nonstock ? 1 : 0);
    formData.append('customername', customerName);
    formData.append('customeraddress', customerAddress);
    formData.append('customerphone', customerPhone);
    formData.append('customeremail', customerEmail);
    formData.append('customernotes', customerNotes);

    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/uploadVehicleData', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to upload file and data');
      }
      const result = await response.json();
      // Get the correct file ID from the server response
      const fileId = result.fileId;

      // Use the text values for manufacturer, range, and model
      const vehicleName = selectedVehicle === 'Other Vehicle (Specify)'
        ? customModelName
        : selectedVehicle;

      // Simplify tuning type
      const simplifiedTuningType = tuningTypeMap[tuningType]?.split(' - ')[0] || '';

      // Simplify and filter selected options
      const simplifiedOptions = Object.keys(options)
        .filter(option => options[option])
        .map(option => {
          switch(option) {
            case 'epa': return 'EPA';
            case 'hybridturbo': return 'HybridTurbo';
            case 'egr': return 'EGR';
            case 'dpf': return 'DPF';
            case 'urea': return 'UREA';
            case 'coldstart': return 'Coldstart';
            case 'decat': return 'O2';
            case 'nsl': return 'NSL';
            case 'startstop': return 'StartStop';
            case 'popsbangs': return 'PopsBangs';
            case 'burbles': return 'Burbles';
            case 'swirlflaps': return 'Swirlflaps';
            case 'tva': return 'TVA';
            case 'nonstock': return 'Non-stock';

            // Add more cases here for other options that need simplification
            default: return optionMap[option].split(' / ')[0].split(' ')[0];
          }
        })
        .join(' | ');

      const notificationBody = `${vehicleName} - ${simplifiedTuningType}${simplifiedOptions ? ` | ${simplifiedOptions}` : ''}`;

      sendNotificationToAdmin(
        `${userProfile.companyName} (${userProfile.name})`,
        `ID ${fileId}: ${notificationBody}`,
        { messageId: `'${fileId}'`, sender: userProfile.name }
      );
      onClose();
      setTimeout(() => {  window.location.reload(); }, 1000);
    } catch (error) {
      console.error('Error uploading file and data:', error);
    } finally {
      setIsSubmitting(false); // Sett til false etter at prosessen er fullført
    }
  };


  const cancelFirstStep = () => {
    setShowFirstConfirmation(false);
  };

  const cancelSecondStep = () => {
    setShowSecondConfirmation(false);
  };

  useEffect(() => {
    fetchCompanyLevel();
  }, [userProfile.did]);

  const fetchCompanyLevel = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/calculate-credits/${userProfile.did}`);
        const data = await response.json();
        setCompanyLevel(data);
    } catch (error) {
        console.error('Error fetching user stats:', error);
    }
};
const [companyLevel, setCompanyLevel] = useState({
  level: '',
  baseCredits: ''
});
const [estimatedCredits, setEstimatedCredits] = useState(companyLevel.baseCredits);
const [showExtraCreditWarning, setShowExtraCreditWarning] = useState(false);
const [show70CreditWarning, setShow70CreditWarning] = useState(false);

const tuningTypeMap = {
  '1': 'ECO - Fair Power, Maximum Economy',
  '3': 'OPTI - Good Power, Improved Fuel Economy',
  '4': 'POWER - High Power, Ultimate Performance',
  '5': 'AS STOCK - No performance Increase'
};

const optionMap = {
  egr: 'EGR / AGR OFF',
  dpf: 'DPF / OPF OFF',
  urea: 'UREA / AdBlue OFF',
  decat: 'DECAT / Rear O2 OFF',
  epa: 'EPA / Fixed VMAX',
  hybridturbo: 'HYBRIDTURBO',
  gearbox: 'Gearbox tune',
  nsl: 'NSL / VMAX OFF',
  coldstart: 'Coldstart OFF',
  startstop: 'Start/Stop Deactivation',
  popsbangs: 'Pops & Bangs',
  burbles: 'Burbles',
  swirlflaps: 'Swirlflaps OFF',
  tva: 'TVA OFF',
  nonstock: 'File not stock'
};



    // Function to handle checkbox changes
    const handleOptionChange = (event) => {
      const { name, checked } = event.target;
      setOptions(prevOptions => ({
        ...prevOptions,
        [name]: checked
      }));
    };

    const calculateCredits = () => {
      let credits = 0;

  // Ensure Euroclass is considered when necessary
  if ([6, 8, 9].includes(parseInt(type)) && !euroClass) {
    setEstimatedCredits('Select Euroclass');
    return;
  }

  // Set custom base credits for type 6, 8, and 9
  const isCustomType = [6, 8, 9].includes(parseInt(type));
  const tuningTypeCredit = isCustomType ? 12 : 4;

      // If tuning type is not AS STOCK and a tuning type is selected, add custom or regular tuning type credits
      if (tuningType && tuningType !== '5') {
        credits += tuningTypeCredit;
      }

      // Add baseCredits if any option is selected or if a tuning type other than AS STOCK is selected
      if (Object.values(options).some(option => option) || (tuningType && tuningType !== '5')) {
        credits += companyLevel.baseCredits;
      }

      // Add credits based on selected options with custom prices for type 6, 8, 9
      if (options.egr) credits += isCustomType ? 15 : 3;
      if (options.dpf) credits += isCustomType ? 15 : 3;
      if (options.urea) credits += isCustomType ? 20 : 5;
      if (options.decat) credits += 2; 
      if (options.epa) credits += 5; 
      if (options.hybridturbo) credits += 7; 
      if (options.gearbox) credits += 5; 
      if (options.nsl) credits += isCustomType ? 10 : 2;
      if (options.coldstart) credits += 2;
      if (options.startstop) credits += 2;
      if (options.popsbangs) credits += 5;
      if (options.burbles) credits += 5;
      if (options.swirlflaps) credits += 2;
      if (options.tva) credits += 2;
      if (options.nonstock) credits += 0;


  if (euroClass === '2' && Object.values(options).some(option => option)) {
    credits = 70;
  }
  if (euroClass === '2' && Object.values(options).some(option => option)) {
    credits = 70;
    setShow70CreditWarning(true);
    setShowExtraCreditWarning(false);
  } else if (credits > 50) {
    credits = 50;
    setShowExtraCreditWarning(true);
    setShow70CreditWarning(false);
  } else {
    setShowExtraCreditWarning(false);
    setShow70CreditWarning(false);
  }
  setEstimatedCredits(credits);
};

useEffect(() => {
  calculateCredits();
}, [tuningType, options, type, euroClass]); // Include euroClass here



  useEffect(() => {
    setShowSuggestions(searchTerm.trim().length > 0 && suggestionsWithOtherOption.length > 0);
  }, [searchTerm, suggestionsWithOtherOption]);

  return (
    <div className="uploadmodal-background">
      <div className="uploadmodal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-btn" onClick={onClose}>CLOSE</button>
        </div>
        <div className="modal-body">
          <div className="left-section">
            <h3>Vehicle</h3>
            <div className="form-group">
              <input
                type="text"
                placeholder="Search vehicle type..."
                value={searchTerm}
                onChange={handleSearch}
                className="uploadsearch-input"
              />
              {showSuggestions && (
                <div className="uploadsearch-results">
                  {suggestionsWithOtherOption.map(model => (
                    <div key={model.id} className="uploadsearch-result-item" onClick={() => selectVehicle(model)}>
                      {model.manufacturerName} {model.rangeName} {model.modelName}
                    </div>
                  ))}
                </div>
              )}
            <b>{selectedVehicle}</b>
            </div>
            {selectedVehicle === 'Other Vehicle (Specify)' && (
  <>
    <select value={type} onChange={handleTypeChange} required>
      <option value="" disabled hidden>Select Type</option>
      <option value="1">Cars</option>
      <option value="6">Truck</option>
      <option value="8">Tractor</option>
      <option value="9">Construction / Stationary</option>
      <option value="10">Marine</option>
      <option value="14">MC / ATV / Snowmobile</option>
    </select>
    <input
      type="text"
      placeholder="Enter full vehicle name"
      value={customModelName}
      onChange={(e) => setCustomModelName(e.target.value)}
      required
    />
    {/* Conditionally render Euroclass dropdown based on selected type */}
    {([6, 8, 9].includes(parseInt(type))) && (
      <select required value={euroClass} onChange={(e) => seteuroClass(e.target.value)}>
        <option value="" disabled hidden>
          Select Euroclass
        </option>
        <option value="1">Euro 4/5</option>
        <option value="2">Euro 6</option>
      </select>
    )}
  </>
)}







            <select
              required
              value={transmissionType}
              onChange={(e) => setTransmissionType(e.target.value)}
            >
              {/* Placeholder option */}
              <option value="" disabled hidden>
                Select Transmission
              </option>
              <option value="2">Auto</option>
              <option value="4">DSG</option>
              <option value="3">Manual</option>
            </select>
            <input
              required
              type="text"
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
              placeholder="Registration Number"
            />

<div className="form-group">
  {([6, 8, 9].includes(type)) && (
    <select required value={euroClass} onChange={(e) => seteuroClass(e.target.value)}>
      <option value="" disabled hidden>
        Select Euroclass
      </option>
      <option value="1">Euro 4/5</option>
      <option value="2">Euro 6</option>
    </select>
  )}
</div>



            <div className="customer-info">
              <h3>Customer Info</h3>
              <input
                placeholder="Customer Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
              <input
                placeholder="Address"
                value={customerAddress}
                onChange={(e) => setCustomerAddress(e.target.value)}
              />
              <input
                placeholder="Phone"
                value={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
              />
              <input
                placeholder="Email"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
              />
              <textarea
                placeholder="Notes"
                value={customerNotes}
                onChange={(e) => setCustomerNotes(e.target.value)}
              />
            </div>


          </div>
          <div className="right-section">
            <div className="form-group">
              <h3>Options</h3>
              <select value={toolType} onChange={(e) => setToolType(e.target.value)}>
        <option value="">Select Tool Type</option>
        <option value="1">Autotuner</option>
        <option value="2">MMFlex</option>
        <option value="3">NewGenius</option>
        <option value="10">Trasdata</option>
        <option value="12">K-Tag</option>
        <option value="11">Kess V3</option>
        <option value="9">Unlisted - Other tool</option>
      </select>
      <select value={tuningType} onChange={(e) => setTuningType(e.target.value)}>
  <option value="">Select Tuning Type</option>
  {/* If fuel_type is 3, only show "POWER" and "AS STOCK" */}
  {fuel_type === 3 ? (
    <>
      <option value="4">POWER - High Power, Ultimate Performance</option>
      <option value="5">AS STOCK - No performance Increase</option>
    </>
  ) : (
    <>
      <option value="1">ECO - Fair Power, Maximum Economy</option>
      <option value="3">OPTI - Good Power, Improved Fuel Economy</option>
      <option value="4">POWER - High Power, Ultimate Performance</option>
      <option value="5">AS STOCK - No performance Increase</option>
    </>
  )}
</select>

      <div className="checkbox-group">
              {renderCheckbox('egr', 'EGR / AGR OFF', 2)}
              {renderCheckbox('dpf', 'DPF / OPF OFF', 2)}
              {renderCheckbox('urea', 'UREA / AdBlue OFF', 5)}
              {renderCheckbox('decat', 'DECAT / Rear O2 OFF', 2)}
              {renderCheckbox('epa', 'EPA / Fixed VMAX', 5)}
              {renderCheckbox('hybridturbo', 'HYBRIDTURBO', 7)}
              {renderCheckbox('gearbox', 'Gearbox tune', 5)}
              {renderCheckbox('nsl', 'NSL / VMAX OFF', 2)}
              {renderCheckbox('coldstart', 'Coldstart OFF', 2)}
              {renderCheckbox('startstop', 'Start/Stop Deactivation', 2)}
              {renderCheckbox('popsbangs', 'Pops & Bangs', 5)}
              {renderCheckbox('burbles', 'Burbles', 5)}
              {renderCheckbox('swirlflaps', 'Swirlflaps OFF', 2)}
              {renderCheckbox('tva', 'TVA OFF', 2)}
              {renderCheckbox('nonstock', 'File not stock', 0)}
            </div>
            </div>
            <div className="credits-info">
              <p>Your credits: {userProfile.companyCredits}</p>
              <p>Basecredits: {companyLevel.baseCredits}</p>
                <p>Estimated credits:
                  {typeof estimatedCredits === 'string' ? (
                    <span style={{ color: 'red' }}>{estimatedCredits}</span>
                  ) : (
                    estimatedCredits
                  )}
                </p>
              {showExtraCreditWarning && (
                <p style={{ color: 'red' }}>
                  This vehicle may cost extra credits and will be calculated after the file is uploaded.
                </p>
              )}
              {show70CreditWarning && (
                <p style={{ color: 'red' }}>
                  This vehicle is an Euro 6 vehicle and may cost up to 70 credits. Final calculations will be done after the file is uploaded.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Comments to file or vehicle</label>
          <textarea
            placeholder="Optional"
            value={comments} // Binder til comments-tilstanden
            onChange={(e) => setComments(e.target.value)} // Oppdaterer comments ved endring
          ></textarea>
          <br />
          ECUFILE <span className="small-italic">(File readout from the ECU / Engine only)</span>
          <input type="file" onChange={handleFileOri} className="file-input" /><br></br>

          {(options.gearbox || options.urea) && (
              <>
                GEARBOX / ACM <span className="small-italic">(File readout from ACM or Transmission if applied)</span>
                <input type="file" onChange={handleFileGearbox} className="file-input" /><br></br>
              </>
            )}


          MISC FILES <span className="small-italic">(Pictures / Faultcodes / PDF`s etc.)</span>
          <input type="file" onChange={handleFileMisc} className="file-input" />

                    {/* Other input fields */}
                    {isAdmin && (
            <div className="admin-member-select">
              <select
                value={selectedMemberId}
                onChange={(e) => setSelectedMemberId(e.target.value)}
              >
                {membersList.map(member => (
                  <option key={member.id} value={member.id}>
                    {member.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {/* Rest of the form */}


          {userProfile.companyCredits >= estimatedCredits ? (
    <button onClick={handleSubmit} className="upload-btn">Upload File</button>
  ) : (
    <button className="upload-btn disabled" disabled>
      You don't have enough credits, please refill...
    </button>
  )}


        </div>
      </div>
      {showFirstConfirmation && (
  <div className="modal-background">
    <div className="uploadconfirmation">
      <div className="modal-header">
        <button className="close-btn" onClick={() => cancelFirstStep()}>CLOSE</button>
      </div>



      {vehicleError ? (
        <>
          <p>{vehicleError}</p>
          <button className="confirm-button" onClick={confirmFirstStep}>BEKREFT</button>
          <button className="decline-button" onClick={cancelFirstStep}>AVBRYT</button>
        </>
      ) : userProfile.country === 'NO' && vehicleInfo?.kjoretoydataListe ? (
        <>
          <p>Registreringsnummeret <b>{vehicleInfo.kjoretoydataListe[0]?.kjoretoyId?.kjennemerke}</b> du har angitt tilhører en: <b>{vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.merke[0]?.merke} {vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse[0] || "Ikke tilgjengelig"}</b>. Bekreft at dette er riktig, eller avbryt for å endre</p>
          <button className="confirm-button" onClick={confirmFirstStep}>BEKREFT</button>
          <button className="decline-button" onClick={cancelFirstStep}>AVBRYT</button>
        </>
      ) : (
        <>
        <p>The registration number lookup is only supported within Norway.</p>

        <button className="confirm-button" onClick={confirmFirstStep}>CONTINUE</button>
        </>
      )}
    </div>
  </div>
)}
{showSecondConfirmation && (
  <div className="modal-background">
    <div className="uploadconfirmation">
      <div className="modal-header">
        <button className="close-btn" onClick={() => cancelSecondStep()}>CLOSE</button>
      </div>
      {userProfile.country === 'NO' && (
        <>
          <p>
            Du har valgt en {selectedVehicle} med<br></br> {tuningTypeMap[tuningType] || 'ikke valgt'}
            {Object.values(options).some(option => option) && (
              <>
                <br></br>{Object.keys(options)
                  .filter(option => options[option])
                  .map(option => optionMap[option])
                  .join(', ')}
              </>
            )}.<br></br><br></br>
            Bekreft at dette er korrekt, og at alle relevante filer og evt. feilkoder er vedlagt. <br></br>
            Feil informasjon, kan påløpe ytterligere leveringstid/kostnader.
          </p>
          <button
            className={`confirm-button ${isSubmitting ? 'disabled' : ''}`}
            onClick={confirmSecondStep}
            disabled={isSubmitting} // Deaktiver knappen når isSubmitting er true
          >
            {isSubmitting ? 'Uploading...' : 'BEKREFT'}
          </button>
          <button className="decline-button" onClick={cancelSecondStep}>AVBRYT</button>
        </>
      )}
      {userProfile.country === 'SE' && (
        <>
          <p>
            Du har valgt en {selectedVehicle} med {tuningTypeMap[tuningType] || 'inte valt'}
            {Object.values(options).some(option => option) && (
              <>
                , med tillvalen: {Object.keys(options)
                  .filter(option => options[option])
                  .map(option => optionMap[option])
                  .join(', ')}
              </>
            )}.
            Bekräfta att detta är korrekt, och att alla relevanta filer och eventuella felkoder är bifogade.
            Vid fel information kan ytterligare leveranstid/kostnader tillkomma.
          </p>
          <button
            className={`confirm-button ${isSubmitting ? 'disabled' : ''}`}
            onClick={confirmSecondStep}
            disabled={isSubmitting} // Deaktiver knappen når isSubmitting er true
          >
            {isSubmitting ? 'Uploading...' : 'BEKRÄFTA'}
          </button>
          <button className="decline-button" onClick={cancelSecondStep}>AVBRYT</button>
        </>
      )}
      {!['NO', 'SE'].includes(userProfile.country) && (
        <>
          <p>
            You have selected a {selectedVehicle} with {tuningTypeMap[tuningType] || 'not selected'}
            {Object.values(options).some(option => option) && (
              <>
                , with options: {Object.keys(options)
                  .filter(option => options[option])
                  .map(option => optionMap[option])
                  .join(', ')}
              </>
            )}.
            Please confirm that this is correct, and that all relevant files and any error codes are attached.
            Incorrect information may incur additional delivery time/costs.
          </p>
          <button
            className={`confirm-button ${isSubmitting ? 'disabled' : ''}`}
            onClick={confirmSecondStep}
            disabled={isSubmitting} // Deaktiver knappen når isSubmitting er true
          >
            {isSubmitting ? 'Uploading...' : 'CONFIRM'}
          </button>
          <button className="decline-button" onClick={cancelSecondStep}>CANCEL</button>
        </>
      )}
    </div>
  </div>
)}



    </div>
  );
}

export default Upload;
