import React, { useState, useEffect } from 'react';
import StatChart from './StatChart';
import Logsite from './Logsite';
import Analytics from './Analytics';
import Development from './Development';
import AddVeh from './AddVeh';
import Customers from './Customers';
import NewsWriting from './NewsWriting';
import Ooh from './Ooh';
import Createuser from './Createuser';
import ManageUsers from './ManageUsers';

export default function Adminsite({ onClose, id, isAdmin, isTuner }) {
    const [activeTab, setActiveTab] = useState('overview');
    const [users, setUsers] = useState([]);
    const [dealerEmployees, setDealerEmployees] = useState([]);
    const [selectedDealerEmployee, setSelectedDealerEmployee] = useState(null);
    const [linkedEmployees, setLinkedEmployees] = useState([]);
    const [nonDealerEmployees, setNonDealerEmployees] = useState([]);
    const [editUserId, setEditUserId] = useState(null);
    const [editField, setEditField] = useState('');
    const [editValue, setEditValue] = useState('');
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
    const [allMembers, setAllMembers] = useState([]);
    const [optiMembers, setOptiMembers] = useState([]);
    const [optiMemberData, setOptiMemberData] = useState([]);
    const [levelData, setLevelData] = useState({}); // New state to store level and uploads to next level
    useEffect(() => {

    }, [id]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    useEffect(() => {

        const fetchOverviewData = async () => {
            const members = await fetchOptiMembers();
            const data = await fetchOptiMemberData();
            const filteredData = data.filter(memberData => members.some(member => member.id === memberData.mid));
            setOptiMembers(members);
            setOptiMemberData(filteredData);
        };


        if (activeTab === 'overview') {
            fetchOverviewData();
        } else if (activeTab === 'users') {
            fetchUsers();
        } else if (activeTab === 'settings') {
            fetchDealerEmployees();
        }
    }, [activeTab]);


      // Fetch level and remaining uploads for each member
  useEffect(() => {
    const fetchLevelData = async () => {
      const newLevelData = {};

      for (const member of optiMemberData) {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/rankandlevel/${member.mid}`);
          const data = await response.json();
          newLevelData[member.mid] = {
            level: data.level,
            remainingUploads: data.filesNeededToLevelup
          };
        } catch (error) {
          console.error(`Error fetching level and uploads for member ${member.mid}:`, error);
          newLevelData[member.mid] = { level: 'N/A', remainingUploads: 'N/A' }; // Default in case of error
        }
      }

      setLevelData(newLevelData);
    };

    if (optiMemberData.length > 0) {
      fetchLevelData();
    }
  }, [optiMemberData]); // Trigger only when optiMemberData is updated


    const fetchOptiMembers = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_SITE_URL + '/members/opti');
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching opti members:', error);
          return [];
        }
    };

    const fetchOptiMemberData = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_SITE_URL + '/uploads/opti-member-data');
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching opti member data:', error);
          return [];
        }
    };

    
    const fetchLevelAndRemainingUploads = async (mid) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/rankandlevel/${mid}`);
          const data = await response.json();
      
          return {
            level: data.level,
            remainingUploads: data.filesNeededToLevelup
          };
        } catch (error) {
          console.error(`Error fetching level and remaining uploads for user ${mid}:`, error);
          return { level: 'N/A', remainingUploads: 'N/A' };  // Return default values if the fetch fails
        }
      };

      
    const renderOverviewTable = () => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Member</th>
                        <th>Total Uploads</th>
                        <th>ND</th> {/* Legg til denne linjen */}
                        <th>Current Year</th>
                        <th>Current Month</th>
                        <th>Current Week</th>
                        <th>Last 90 Days</th>
                        <th>Level</th>
                        <th>Uploads to Next Level</th>
                    </tr>
                </thead>
                <tbody>
                    {optiMemberData.map(data => {
                        const member = optiMembers.find(member => member.id === data.mid);

  const levelInfo = levelData[data.mid] || { level: 'Loading...', remainingUploads: 'Loading...' };

                        return (
                            <tr key={data.mid}>
                                <td>{data.rank}</td>
                                <td>{member ? member.name : data.mid}</td>
                                <td>{data.total}</td>
                                <td>{data.wip}</td> {/* Legg til denne linjen */}
                                <td>{data.current_year}</td>
                                <td>{data.current_month}</td>
                                <td>{data.current_week}</td>
                                <td>{data.last_90_days}</td>
                                <td>{levelInfo.level}</td> {/* Display the fetched level */}
                                <td>{levelInfo.remainingUploads}</td> {/* Display the fetched remaining uploads */}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const fetchUsers = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/users');
            const data = await response.json();
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setUsers(sortedData);
            setAllMembers(sortedData); // Set all members for the dropdown
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchDealerEmployees = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/dealerEmployees');
            const data = await response.json();
            // Sort dealer employees alphabetically by name
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setDealerEmployees(sortedData);
        } catch (error) {
            console.error('Error fetching dealer employees:', error);
        }
    };

    const fetchNonDealerEmployees = async (dealerId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/nonDealerEmployees?dealerId=${dealerId}`);
            const data = await response.json();
            // Sort non-dealer employees alphabetically by name
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setNonDealerEmployees(sortedData);
        } catch (error) {
            console.error('Error fetching non-dealer employees:', error);
        }
    };
    

    const fetchLinkedEmployees = async (dealerId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/linkedEmployees?dealerId=${dealerId}`);
            const data = await response.json();
            // Sort linked employees alphabetically by name
            const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
            setLinkedEmployees(sortedData);
        } catch (error) {
            console.error('Error fetching linked employees:', error);
        }
    };

    const handleDealerEmployeeClick = async (dealerId) => {
        setSelectedDealerEmployee(dealerId);
        await fetchLinkedEmployees(dealerId);
        await fetchNonDealerEmployees(dealerId);
    };

    const handleLinkEmployee = async (employeeId, dealerId) => {
        try {
            await fetch(process.env.REACT_APP_SITE_URL + '/linkEmployee', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ employeeId, dealerId }),
            });
            await fetchLinkedEmployees(dealerId);
            await fetchNonDealerEmployees(dealerId);
        } catch (error) {
            console.error('Error linking employee:', error);
        }
    };

    const handleUnlinkEmployee = async (employeeId, dealerId) => {
        try {
            await fetch(process.env.REACT_APP_SITE_URL + '/unlinkEmployee', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ employeeId, dealerId }),
            });
            await fetchLinkedEmployees(dealerId);
            await fetchNonDealerEmployees(dealerId);
        } catch (error) {
            console.error('Error unlinking employee:', error);
        }
    };

    const handleSaveEdit = async () => {
        setShowConfirmationPopup(true);
    };

    const confirmSaveEdit = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/updateUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: editUserId, field: editField, value: editValue }),
            });

            if (response.ok) {
                setUsers(users.map(user => user.id === editUserId ? { ...user, [editField]: editValue } : user));
                setEditUserId(null);
                setEditField('');
                setEditValue('');
                setShowEditPopup(false);
                setShowConfirmationPopup(false);
            } else {
                console.error('Failed to update user');
            }
        } catch (error) {
            console.error('Error updating user:', error);
        }
    };

    const getDealerName = (did) => {
        const dealer = allMembers.find(member => member.id === did);
        return dealer ? dealer.name : did;
    };





    

    const renderContent = () => {
        switch (activeTab) {
            case 'overview':
                return (
                    <div>
                        <h3>Ranks & Levels</h3>
                        {renderOverviewTable()}
                    </div>
                );
            case 'users':
                return <ManageUsers allMembers={allMembers} />; // Bruk den nye komponenten
            case 'settings':
                return (
                    <div className="dealer-employees-section">
                        <div className="dealer-employees-list">
                            <h3>Dealer Employees</h3>
                            {dealerEmployees.map(employee => (
                                <div key={employee.id} className="employee-item" onClick={() => handleDealerEmployeeClick(employee.id)}>
                                    {employee.name}
                                </div>
                            ))}
                        </div>
                        <div className="linked-employees-section">
                            {selectedDealerEmployee && (
                                <div>
                                    <h3>Linked Employees for Dealer: {getDealerName(selectedDealerEmployee)}</h3>
                                    {linkedEmployees.map(employee => (
                                        <div key={employee.id} className="employee-item" onClick={() => handleUnlinkEmployee(employee.id, selectedDealerEmployee)}>
                                            {employee.name}
                                        </div>
                                    ))}
                                    <h4>Link Non-Dealer Employees</h4>
                                    {nonDealerEmployees
                                        .filter(employee => !linkedEmployees.some(linked => linked.id === employee.id))
                                        .map(employee => (
                                            <div key={employee.id} className="employee-item" onClick={() => handleLinkEmployee(employee.id, selectedDealerEmployee)}>
                                                {employee.name}
                                            </div>
                                        ))}
                                </div>
                            )}
                        </div>
                    </div>
                );
                
            case 'logs':
                return (
                    <div>
                    <Logsite />
                    </div>
                );
            case 'development':
                return (
                    <div>
                        <Development />
                    </div>
                );
            case 'statistics':
                return (
                    <div>
                        <StatChart />
                    </div>
                );
            case 'analytics':
                return (
                    <div>
                        <Analytics />
                    </div>
                );
            case 'addveh':
                return (
                    <div>
                        <AddVeh />
                    </div>
                );
            case 'newswriting':
                return <NewsWriting id={id} onClose={onClose} />;
            case 'customers':
                return <Customers />;
            case 'ooh':
                return <Ooh id={id} onClose={onClose} />;
            case 'createuser':
                return <Createuser />;
            default:
                return <div>Overview Content</div>;
        }
    };

    return (
        <div className="adminmodal-background">
            <div className="adminmodal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
            <button className="close-btn" onClick={onClose}>CLOSE</button>
            </div>
            <div className="admin-header">
                </div>







                <div className="admin-tabs">
                    <div onClick={() => setActiveTab('overview')}>Ranks & Levels</div>
                    {isAdmin && (<div onClick={() => setActiveTab('createuser')}>Create User</div>)}
                    {isAdmin && (<div onClick={() => setActiveTab('users')}>Manage Users</div>)}
                    {isAdmin && (<div onClick={() => setActiveTab('settings')}>Dealer Employees</div>)}
                    <div onClick={() => setActiveTab('logs')}>View Logs</div>
                    <div onClick={() => setActiveTab('development')}>Development</div>
                    <div onClick={() => setActiveTab('statistics')}>Statistics</div>
                    <div onClick={() => setActiveTab('analytics')}>Analytics</div>
                    <div onClick={() => setActiveTab('addveh')}>Add Vehicle</div>
                    <div onClick={() => setActiveTab('newswriting')}>New Article</div>
                    <div onClick={() => setActiveTab('customers')}>Customers</div>
                    <div onClick={() => setActiveTab('ooh')}>Out of Hours</div>
                </div>
                <div className="admin-content">
                    {renderContent()}
                </div>


                {showEditPopup && editField !== 'credit' && (
                    <div className="modal-background" onClick={() => setShowEditPopup(false)}>
                        <div className="edit-modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                    <button className="close-btn" onClick={() => setShowEditPopup(false)}>CLOSE</button>
                </div>
                            <h3>Edit {editField.charAt(0).toUpperCase() + editField.slice(1)}</h3>
                            <select value={editValue} onChange={(e) => setEditValue(e.target.value)}>
                                <option value="1">YES</option>
                                <option value="0">NO</option>
                            </select><br></br>
                            <button className="admin-button" onClick={handleSaveEdit}>SAVE</button>
                        </div>
                    </div>
                )}

                {showEditPopup && editField === 'credit' && (
                    <div className="modal-background" onClick={() => setShowEditPopup(false)}>
                        <div className="edit-modal-content" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                    <button className="close-btn" onClick={() => setShowEditPopup(false)}>CLOSE</button>
                </div>
                            <h3>Edit Credits</h3>
                            <input
                                type="number"
                                value={editValue}
                                onChange={(e) => setEditValue(e.target.value)}
                            /><br></br>
                            <button className="admin-button" onClick={handleSaveEdit}>SAVE</button>
                        </div>
                    </div>
                )}

                {showConfirmationPopup && editField !== 'credit' && (
                    <div className="modal-background" onClick={() => setShowConfirmationPopup(false)}>
                        <div className="edit-modal-content" onClick={(e) => e.stopPropagation()}>

                            <h3>Confirm Changes</h3>
                            <p>Are you sure you want to change {editField} to {editValue === '1' ? 'YES' : 'NO'}?</p>

                            <button className="admin-button" onClick={confirmSaveEdit}>CONFIRM</button>
                            <button className="tos-button" onClick={() => setShowConfirmationPopup(false)}>CANCEL</button>

                        </div>
                    </div>
                )}

                {showConfirmationPopup && editField === 'credit' && (
                    <div className="modal-background" onClick={() => setShowConfirmationPopup(false)}>
                        <div className="edit-modal-content" onClick={(e) => e.stopPropagation()}>

                            <h3>Confirm Changes</h3>
                            <p>Are you sure you want to change the credits to {editValue}?</p>
                            <button className="admin-button" onClick={confirmSaveEdit}>CONFIRM</button>
                            <button className="tos-button" onClick={() => setShowConfirmationPopup(false)}>CANCEL</button>


                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
