import React, { useState, useEffect } from 'react';

export default function HourBank({ userProfile, id }) {
  const userId = id || userProfile?.id;
  const [allTotals, setAllTotals] = useState([]);
  const [allLogs, setAllLogs] = useState([]);
  const [totalHours, setTotalHours] = useState(0);   // single user's total
  const [hoursLog, setHoursLog] = useState([]);      // single user's log
  const [hoursValue, setHoursValue] = useState('');  // input for +/– hours
  const [commentValue, setCommentValue] = useState(''); // input for comment
  const [deletedEntries, setDeletedEntries] = useState([]);

  useEffect(() => {
    fetchAllTotals();
    fetchAllLogs();
    fetchDeletedEntries();
  }, []);
  useEffect(() => {
    if (userId) {
      fetchUserHoursLog(userId);
    }
  }, [userId]);

  const fetchAllTotals = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/timebank/totals`);
      if (!response.ok) throw new Error('Failed to fetch ALL totals');
      const data = await response.json();
      setAllTotals(data);
    } catch (error) {
      console.error('Error fetching all totals:', error);
    }
  };

  const fetchDeletedEntries = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/timebank/deleted-all`);
      if (!response.ok) throw new Error('Failed to fetch deleted entries');
      const data = await response.json();
      setDeletedEntries(data);
    } catch (error) {
      console.error('Error fetching deleted entries:', error);
    }
  };
  

  const fetchAllLogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/timebank/all`);
      if (!response.ok) throw new Error('Failed to fetch all logs');
      const data = await response.json(); 
      setAllLogs(data);
    } catch (error) {
      console.error('Error fetching all logs:', error);
    }
  };


  const fetchUserHoursLog = async (uid) => {
    try {
      const logResp = await fetch(`${process.env.REACT_APP_SITE_URL}/timebank/log/${uid}`);
      if (!logResp.ok) throw new Error('Failed to fetch single user log');
      const logData = await logResp.json();
      setHoursLog(logData);
      const match = allTotals.find((row) => row.user_id === uid);
      const userTotal = match?.total_hours || 0;
      setTotalHours(userTotal);
    } catch (error) {
      console.error('Error fetching single user data:', error);
    }
  };

  const handleAddHours = async (e) => {
    e.preventDefault();
    if (!userId) {
      alert('No user ID found');
      return;
    }
    const hoursInt = parseInt(hoursValue, 10);
    if (isNaN(hoursInt)) {
      alert('Please enter a valid integer for hours (+ or -).');
      return;
    }
    const payload = {
      userId: userId,
      hours: hoursInt,
      comment: commentValue,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/timebank/add`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      if (!response.ok) throw new Error('Failed to add hours');
      await fetchAllTotals();        // update the big totals table
      await fetchAllLogs();          // update the big all-entries list
      await fetchUserHoursLog(userId); 
      setHoursValue('');
      setCommentValue('');
    } catch (error) {
      console.error('Error adding hours:', error);
    }
  };

  const handleDelete = async (entryId) => {
    if (!userId) {
      alert('No user ID found for the "deletedBy" field.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/timebank/delete/${entryId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ deletedBy: userId }),
      });

      if (!response.ok) {
        const { message } = await response.json();
        throw new Error(message || 'Failed to delete entry');
      }
      await fetchAllTotals();
      await fetchAllLogs();
      if (userId) {
        await fetchUserHoursLog(userId);
      }
      await fetchDeletedEntries();
    } catch (error) {
      console.error('Error deleting entry:', error);
      alert(`Error deleting entry: ${error.message}`);
    }
  };

  const totalColor = totalHours < 0 ? 'red' : 'green';

  return (
    <div style={{ padding: '1rem' }}>
      <section style={{ marginBottom: '2rem' }}>
        <h2>Timebank</h2>
        {allTotals.length === 0 ? (
          <p>No timebank data found for any user.</p>
        ) : (
          <table style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: '1px solid #ccc', padding: '6px' }}>User ID</th>
                <th style={{ borderBottom: '1px solid #ccc', padding: '6px' }}>Total Hours</th>
              </tr>
            </thead>
            <tbody>
            {allTotals.map(({ user_id, user_name, total_hours }) => {
              const color = total_hours < 0 ? 'red' : 'green';
              return (
                <tr key={user_id}>
                  <td style={{ padding: '6px' }}>
                    {user_name}
                  </td>
                  <td style={{ padding: '6px', color }}>
                    {total_hours}
                  </td>
                </tr>
              );
            })}
            </tbody>
          </table>
        )}
      </section>
      <section>
        <form onSubmit={handleAddHours} style={{ marginBottom: '1rem' }}>
        Skal du covre for noen? Skriv antall timer (f.eks. <strong>2</strong>).<br />
        Skal du ha fri og får cover? Bruk minus foran (f.eks. <strong>-2</strong>).<br /><br />

          <label>Timer :</label>
          <input
            type="number"
            value={hoursValue}
            onChange={(e) => setHoursValue(e.target.value)}
            placeholder="antall timer i hele tall"
            style={{ marginLeft: '0.5rem' }}
          />
          <br /><br />
          <label>Kommentar:</label>
          <input
            type="text"
            value={commentValue}
            onChange={(e) => setCommentValue(e.target.value)}
            placeholder="Hvorfor"
            style={{ marginLeft: '0.5rem' }}
          />
          <br /><br />

          <button type="submit">Add Hours</button>
        </form>
        <section style={{ marginBottom: '2rem' }}>
          <h3>Entries</h3>
          {allLogs.length === 0 ? (
            <p>No entries yet.</p>
          ) : (
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {allLogs.map((entry) => (
                <li key={entry.id} style={{ marginBottom: '0.5rem' }}>
                  <strong style={{ color: entry.hours < 0 ? 'red' : 'green' }}>
                    {entry.hours > 0 ? `+${entry.hours}` : entry.hours}
                  </strong>{' '}
                  hrs - {' '}
                  <span style={{ fontStyle: 'italic' }}>
                    {entry.user_name ?? `User #${entry.user_id}`}
                  </span>{' '}
                  {entry.comment && `— ${entry.comment}`}{' '}
                  <small style={{ color: '#888' }}>
                    ({new Date(entry.created_at).toLocaleString()})
                  </small>
                  {' '}
                  <button
                    onClick={() => handleDelete(entry.id)}
                    style={{ marginLeft: '1rem', color: 'white', backgroundColor: 'red' }}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          )}
        </section>
        <section style={{ marginBottom: '2rem' }}>
      <h3>Deleted Entries</h3>
      {deletedEntries.length === 0 ? (
        <p>No deleted entries found.</p>
      ) : (
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {deletedEntries.map((entry) => (
            <li key={entry.id} style={{ marginBottom: '0.5rem' }}>
              <strong style={{ color: entry.hours < 0 ? 'red' : 'green' }}>
                {entry.hours > 0 ? `+${entry.hours}` : entry.hours}
              </strong>{' '}
              hrs -{' '}
              <span style={{ fontStyle: 'italic' }}>
                {entry.user_name ?? `User #${entry.user_id}`}
              </span>
              {entry.comment && ` — ${entry.comment}`}
              <small style={{ color: '#888', marginLeft: '0.5rem' }}>
                (Deleted at: {new Date(entry.deleted_at).toLocaleString()})
              </small>
              {entry.deleted_by_name && (
                <small style={{ marginLeft: '0.5rem', color: '#888' }}>
                  [Deleted by: {entry.deleted_by_name}]
                </small>
              )}
            </li>
          ))}
        </ul>
      )}
    </section>



      </section>
    </div>
  );
}
