import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

const SearchAndFilterSection = React.memo(({
  setSearchText,
  selectedDealer,
  setSelectedDealer,
  dealers,
  isAdmin,
  isTuner,
  wipOnly,
  setWipOnly
}) => {

  const [showModal, setShowModal] = useState(false);
  const [globalSearchText, setGlobalSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const [showAcceptStep, setShowAcceptStep] = useState(false);
  const [noMatchMessage, setNoMatchMessage] = useState('');

  const debouncedSearch = useCallback(
    debounce(value => setSearchText(value), 500),
    [setSearchText]
  );

  const handleDealerChange = useCallback(e => {
    setSelectedDealer(e.target.value);
  }, [setSelectedDealer]);

  const toggleModal = () => {
    if (showModal) {
      setGlobalSearchText('');
      setSearchResults([]);
      setIsLoading(false);
      setIsMatch(false);
      setShowAcceptStep(false);
      setNoMatchMessage('');
    }
    setShowModal(!showModal);
  };

  const handleGlobalSearch = async () => {
    if (globalSearchText.trim() === '') {
      alert('Please enter a registration number.');
      return;
    }

    setIsLoading(true);
    setNoMatchMessage('');
    setSearchResults([]);

    setTimeout(async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/search-by-regnum?regnum=${encodeURIComponent(globalSearchText)}`);

        if (!response.ok) {
          throw new Error('Failed to fetch search results');
        }
        const results = await response.json();
        if (results.length > 0) {
          setSearchResults(results);
          setIsMatch(true);
          setShowAcceptStep(true); // Show the accept step in the same modal
        } else {
          setIsMatch(false);
          setNoMatchMessage('No match found for the entered registration number.');
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
        setNoMatchMessage('No match to this vehicle...');
      } finally {
        setIsLoading(false);
      }
    }, 20000); // 20 seconds delay
  };

  const handleAccept = () => {
    setShowAcceptStep(false);
  };

  const sortedDealers = [...dealers].sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="search-and-filter-section">
      <div className="search-section">
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => debouncedSearch(e.target.value)}
        />
      </div>
      {(isAdmin || isTuner) && (
        <div className="filter-section">
          <select value={selectedDealer} onChange={handleDealerChange}>
            <option value="">All dealers</option>
            {sortedDealers.map((dealer) => (
              <option key={dealer.id} value={dealer.id}>{dealer.name}</option>
            ))}
          </select>
        </div>
      )}
      {(isAdmin || isTuner) && (
        <button 
          className={`toggle-wip-button ${wipOnly ? 'active' : ''}`} 
          onClick={() => setWipOnly(!wipOnly)}
        >
          WIP
        </button>
      )}
      {!(isAdmin || isTuner) && (
        <button 
          className={`toggle-wip-button ${wipOnly ? 'active' : ''}`} 
          onClick={toggleModal}
        >
          Global search
        </button>
      )}

      {showModal && (
        <div className="modal-background" onClick={toggleModal}>
          <div className="uploadconfirmation" onClick={e => e.stopPropagation()}>
            <h3>Global Search</h3>
            <input
              type="text"
              placeholder="Enter registration number"
              value={globalSearchText}
              onChange={(e) => setGlobalSearchText(e.target.value)}
            /><br></br>
            <button className="confirm-button" onClick={handleGlobalSearch}>Search</button>
            <button className="decline-button" onClick={toggleModal}>Close</button>
            
            {isLoading ? (
              <div className="loading-animation">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
            ) : (
              <>
                {noMatchMessage && <p className="no-match-message">{noMatchMessage}</p>}

                {showAcceptStep ? (
                  <div className="accept-step">
<p>
  The information about the vehicle is strictly confidential and must not be shared with others. Please be aware that previous work may have been reset, updated, or modified by other parties afterward.
</p>

                    <button className="confirm-button" onClick={handleAccept}>Accept</button>
                  </div>
                ) : (
                  <div className="search-results">
                    {/* Show search results here */}
                    {searchResults.map(result => (
                      <div key={result.id} className="search-result-item">
                        <p>{result.manufacturer} {result.range} {result.model}</p>
                        <p>{result.tuningTypeDescription}</p>
                        <p>{new Date(result.created).toLocaleDateString()} by {result.dealerName}</p>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
});

export default SearchAndFilterSection;
