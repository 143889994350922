import React, { useState, useEffect } from 'react';

const ProfileSection = React.memo(({
  userProfile,
  isAdmin,
  isTuner,
  userDid,  // Access userDid prop
  userId,  // Access userDid prop
  showWarning,
  taskCountToday,
  pendingCount,
  openProfileModal,
  openAdminModal,
  openOohModal,
  openTosModal,
  handleLogout,
  fetchVehicleData // Callback for å oppdatere data når autorefresh er på
}) => {

  const [rank, setRank] = useState(0);
  const [level, setLevel] = useState(0);
  const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(false); // Flyttet autorefresh state hit

  // Hent autorefresh-status ved lasting
  useEffect(() => {
    const fetchRefreshStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/get-refresh-status/${userId}`);
        const data = await response.json();
        if (response.ok) {
          setIsAutoRefreshOn(data.refresh === 1);
        } else {
          console.error('Failed to fetch refresh status:', data.message);
        }
      } catch (error) {
        console.error('Error fetching refresh status:', error);
      }
    };

    if (userId) {
      fetchRefreshStatus();
    }
  }, [userId]);

  const toggleAutoRefresh = async () => {
    const newStatus = !isAutoRefreshOn;
    console.log(`Toggling auto-refresh for user ID: ${userId} to status: ${newStatus ? 1 : 0}`);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-refresh-status/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userProfile.authToken}`
        },
        body: JSON.stringify({ refresh: newStatus ? 1 : 0 })
      });
      if (response.ok) {
        console.log(`Auto-refresh status successfully updated for user ID: ${userId}`);
        setIsAutoRefreshOn(newStatus);
      } else {
        console.error('Failed to update refresh status');
      }
    } catch (error) {
      console.error('Error updating refresh status:', error);
    }
  };
  
  

  // Function to fetch rank and level
  const fetchRankAndLevel = async () => {
    // console.log(`Fetching rank and level for userDid: ${userDid}`);  // Log userDid
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/rankandlevel/${userDid}`);
      const data = await response.json();

      // Log fetched data
      // console.log(`Fetched rank: ${data.rank}, level: ${data.level} for userDid: ${userDid}`);

      // Update state with rank and level
      setRank(data.rank);
      setLevel(data.level);
    } catch (error) {
      console.error("Error fetching rank and level:", error);
    }
  };

  // Fetch rank and level when the component is mounted or when userDid changes
  useEffect(() => {
    if (userDid) {
      // console.log(`userDid changed or component mounted: ${userDid}`);
      fetchRankAndLevel();
    }
  }, [userDid]);  // Added userDid as a dependency

  useEffect(() => {
    if (isAutoRefreshOn && fetchVehicleData) {
      console.log('Auto-refresh is ON. Fetching vehicle data every 25 seconds.');
      const interval = setInterval(fetchVehicleData, 20000); // Sett intervallet til 25 sekunder
      return () => clearInterval(interval);
    }
  }, [isAutoRefreshOn, fetchVehicleData]);

  return (
    <div className="profile-column">
      <span>{userProfile.name} - {userProfile.companyName}</span><br />
      <span>Your credits: {userProfile.companyCredits}</span><br />
      {(!isAdmin && !isTuner && showWarning) && (
        <span style={{ color: 'red' }}>
          Task scheduled after closing time! Request an OOH tuner.<br />
        </span>
      )}
      {(isAdmin || isTuner) && (
        <span>
          Expected files today: {taskCountToday}
          {pendingCount > 0 && (
            <span style={{ color: 'red' }}> - Pending ooh requests: {pendingCount}</span>
          )}
          <br />
        </span>
      )}
      <span>Your Rank: {rank}</span><br />
      <span>Your Level: {level}</span><br />
      <div className="button-container">
        <div className="left-buttons">
          <button className="profile-button" onClick={openProfileModal}>Dashboard</button>
          {(isAdmin || isTuner) && (
            <button className="admin-button" onClick={openAdminModal}>ADMIN</button>
          )}
          {(!isAdmin && !isTuner) && (
            <button className="ooh-button" onClick={openOohModal}>Request OOH tuner</button>
          )}
          <button className="tos-button" onClick={openTosModal}>Terms of Service</button>
          <div className="auto-refresh-slider">
            <span className="auto-refresh-text">Autorefresh</span>
            <label>
              <input
                type="checkbox"
                checked={isAutoRefreshOn}
                onChange={toggleAutoRefresh}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        <button className="logout-button" onClick={handleLogout}>LOG OUT</button>
      </div>
    </div>
  );
});

export default ProfileSection;