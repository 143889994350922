import React, { memo, useState, useEffect } from 'react';
import { SvgAT, SvgD, SvgT, SvgM } from './SvgIcons';
import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import alert from '../assets/alert.gif';
import of_car from '../assets/of_car.png';
import of_construction from '../assets/of_construction.png';
import of_marine from '../assets/of_marine.png';
import of_mc from '../assets/of_mc.png';
import of_tracktor from '../assets/of_tracktor.png';
import of_truck from '../assets/of_truck.png';
import autotuner from '../assets/autotuner.png';
import newgenius from '../assets/newgenius.png';
import trasdata from '../assets/trasdata.png';
import other from '../assets/other.png';
import rankOtherIcon from '../assets/rankother.png';
import vpIcon from '../assets/vp.png';
import prio1 from '../assets/nyprio1.png';
import prio2 from '../assets/nyprio2.png';
import prio3 from '../assets/nyprio3.png';



const getVehicleImage = (v_type) => {
  switch (v_type) {
    case 1:
      return <img src={of_car} alt="Car" className="vehicle-image" />;
    case 6:
      return <img src={of_truck} alt="Truck" className="vehicle-image" />;
    case 8:
      return <img src={of_tracktor} alt="Tractor" className="vehicle-image" />;
    case 9:
      return <img src={of_construction} alt="Construction" className="vehicle-image" />;
    case 10:
      return <img src={of_marine} alt="Marine" className="vehicle-image" />;
    case 14:
      return <img src={of_mc} alt="MC/ATV/Snowmobile" className="vehicle-image" />;
    default:
      return null; // Handle cases where v_type is not recognized
  }
};

const getIcon = (dealerName) => {
  if (dealerName?.startsWith("OptiTuning")) {
    return <img src={rankOtherIcon} alt="Optituning Icon" className="dealer-icon" />;
  } else if (dealerName?.startsWith("VP")) {
    return <img src={vpIcon} alt="VP Icon" className="dealer-icon" />;
  }
  return null; // Ingen ikon for øvrige
};


const VehicleRow = memo(({
  vehicle,
  isAdmin,
  isTuner,
  handleRowClick,
  handleUpdateStatus,
  handleDelete
}) => {

  const showAlert =
    ((isAdmin || isTuner) && vehicle.message === 1 && vehicle.tunercheck === 1) ||
    (!isAdmin && !isTuner && vehicle.message === 2 && vehicle.dealercheck === 1);



  const getTuningTypeLabel = (tuningTypeId) => {
    const tuningTypes = {
      '1': { label: 'E', className: 'eco' },
      '3': { label: 'O', className: 'opti' },
      '4': { label: 'P', className: 'power' },
      '5': { label: 'S', className: 'stock' },
      '6': { label: 'X', className: 'extreme' }
    };
    return tuningTypes[tuningTypeId] || { label: 'Ukjent', className: '' };
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateTimeString));
  };

  const getTooltypeImage = (tooltype) => {
    switch (tooltype) {
      case 1:
        return <img src={autotuner} alt="Autotuner" className="tooltype-image" />;
      case 3:
        return <img src={newgenius} alt="NewGenius" className="tooltype-image" />;
      case 10:
        return <img src={trasdata} alt="Trasdata" className="tooltype-image" />;
      case 9:
        return <img src={other} alt="Other" className="tooltype-image" />;
      default:
        return <img src={other} alt="Other" className="tooltype-image" />;
    }
  };

  const getPriorityImage = (priority) => {
    switch (priority) {
      case 1:
        return <img src={prio1} alt="Priority 1" className="priority-image" />;
      case 2:
        return <img src={prio2} alt="Priority 2" className="priority-image" />;
      case 3:
        return <img src={prio3} alt="Priority 3" className="priority-image" />;
      default:
        return <span></span>; // Hvis det ikke er 1, 2 eller 3, vis tallet
    }
  };


  const tunerNames = {
    3: { name: 'Jan', color: '#04363a31' }, // Oransje
    33: { name: 'Anders', color: '#04363a31' }, // Grønn
    48: { name: 'Andre', color: '#04363a31' }, // Blå
    10: { name: 'Arnt', color: '#04363a31' }, // Rosa
    37: { name: 'Marius', color: '#095c294d' }, // Gul
    135: { name: 'AuRes', color: '#FFC300' }, // Gul

};

  return (
    <tr className={`status-${vehicle.status}`} onClick={() => handleRowClick(vehicle.id)}>
      <td>{vehicle.id}</td>


      {(isAdmin || isTuner) &&
(parseInt(vehicle.status) !== 2 || ((isAdmin || isTuner) && vehicle.tunercheck === 1) || (!isAdmin && !isTuner && vehicle.dealercheck === 1)) && (
  <td>{getPriorityImage(vehicle.priority)}</td>
)

}


      <td>{vehicle.vehicleDescription || 'Ukjent Kjøretøy'}</td>
    <td>{vehicle.regnum.toUpperCase()}</td>



    <td>
    {getIcon(vehicle.dealerName)}
      {vehicle.dealerName || 'Ukjent Dealer'}
    </td>

    <td className="alert-cell">
  {showAlert && (
    <IonIcon
      icon={alertCircleOutline}
      color="danger"
      className="blinking-alert"
      style={{ fontSize: '25px' }}
    />
  )}
</td>




      <td>

      <span className={`tuningType ${getTuningTypeLabel(vehicle.tuning_type).className}`}>{getTuningTypeLabel(vehicle.tuning_type).label}</span>
      {vehicle.egr !== 0 && <span className="file-option-small egr">E</span>}
      {vehicle.dpf !== 0 && <span className="file-option-small dpf">D</span>}
      {vehicle.adblue !== 0 && <span className="file-option-small adblue">A</span>}
      {vehicle.nsl !== 0 && <span className="file-option-small nsl">NSL</span>}
      {vehicle.o2 !== 0 && <span className="file-option-small o2">O2</span>}
      {vehicle.epa !== 0 && <span className="file-option-small epa">EPA</span>}
      {vehicle.hybridturbo !== 0 && <span className="file-option-small hybrid-turbo">HT</span>}
      {vehicle.gearbox !== 0 && <span className="file-option-small gearbox">GT</span>}
      {vehicle.coldstart !== 0 && <span className="file-option-small cold-start">CS</span>}
      {vehicle.startstop !== 0 && <span className="file-option-small start-stop">SS</span>}
      {vehicle.popsbangs !== 0 && <span className="file-option-small pops-bangs">PB</span>}
      {vehicle.burbles !== 0 && <span className="file-option-small burbles">BU</span>}
      {vehicle.swirlflaps !== 0 && <span className="file-option-small swirl-flaps">SW</span>}
      {vehicle.tva !== 0 && <span className="file-option-small tva">TVA</span>}
      {vehicle.analysis !== 0 && <span className="file-option-small tva">NS</span>}
      </td>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        {getVehicleImage(vehicle.v_type)}
      </td>
            {!(isAdmin || isTuner) && (
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <span className={`status-box ${vehicle.paid === 1 ? 'paid' : 'not-paid'}`}>
            {vehicle.paid === 1 ? '✔' : '✖'}
          </span>
        </td>
      )}

{(isAdmin || isTuner) && (

<td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
    <span
        style={{
            backgroundColor: tunerNames[vehicle.tuner]?.color || '#1e1e1e', // Bakgrunnsfarge rundt teksten
            color: '#ffffff', // Lys tekst for kontrast
            padding: '2px 8px', // Polstring rundt teksten
            borderRadius: '4px', // Myke hjørner
            display: 'inline-block', // Sørg for at det omslutter teksten
            minWidth: '40px', // Minimum bredde (valgfritt)
            textAlign: 'center',
        }}
    >
        {tunerNames[vehicle.tuner]?.name || vehicle.tuner}
    </span>
</td>


)}

      <td>{formatDateTime(vehicle.created)}</td>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{vehicle.charged}</td>
      {(isAdmin || isTuner) && (
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      {getTooltypeImage(vehicle.tooltype)}
    </td>

      )}
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <div className="action-buttons">
        {(parseInt(vehicle.status) !== 2 || ((isAdmin || isTuner) && vehicle.tunercheck === 1) || (!isAdmin && !isTuner && vehicle.dealercheck === 1)) && (
  (() => {
    if ((!isAdmin && !isTuner) && parseInt(vehicle.status) !== 2) {
      return null;
    } else {
      return (
        <button
          className="action-button done-button"
          onClick={(e) => {
            e.stopPropagation();
            handleUpdateStatus(vehicle.id, 2, isAdmin || isTuner);
          }}
        >
          Done
        </button>
      );
    }
  })()
)}

          {(isAdmin || isTuner) && (
            <>
 <div className="action-buttons">
          <button
            className="actionbuttons"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(vehicle.id);
            }}
          >
            <img src="/images/red-trash-can-icon.svg" alt="Delete" className="delete-icon" />
          </button>
          <button
            className="actionbuttons"
            onClick={(e) => {
              e.stopPropagation();
              // console.log("Rewind action clicked for vehicle ID:", vehicle.id);
            }}
          >
            <img src="/images/redo.png" alt="Rewind" className="credback-icon" />
          </button>
        </div>

            </>
          )}
        </div>
      </td></tr>

  );
});

export default VehicleRow;

