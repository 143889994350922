import React from 'react';
import './Media.css';



const Media = () => {
    return (
        <div className="media-container">
            SØPPEL Å SÅP
        </div>
    );
};

export default Media;
