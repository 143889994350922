import React, { useState, useEffect, useCallback } from 'react';
import '../App.css';
import ManualsModal from './Manualsmodal';

export default function Manuals() {
    const [categories, setCategories] = useState([]); // Categories list
    const [searchTerm, setSearchTerm] = useState(''); // Search term
    const [selectedCategory, setSelectedCategory] = useState(null); // Selected category
    const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Selected subcategory
    const [manualContent, setManualContent] = useState(null); // Content of the selected manual
    const [isAdmin, setIsAdmin] = useState(true); // Admin mode
    const [showAdminPanel, setShowAdminPanel] = useState(false); // State for showing/hiding admin panel
    const [subcategories, setSubcategories] = useState([]); // Subcategories list
    const [manualTitles, setManualTitles] = useState([]); // Manual titles list
    const [selectedManual, setSelectedManual] = useState(null); // Selected manual for detail view
    const [showManualsModal, setShowManualsModal] = useState(false);
    const [selectedSubSubcategory, setSelectedSubSubcategory] = useState(null); // Track selected sub-subcategory
    const [subSubcategories, setSubSubcategories] = useState([]); // Store sub-subcategories

    useEffect(() => {
        fetchCategories();
    }, []);

    // Fetch categories from API
    const fetchCategories = async () => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/categories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            console.log('Fetched categories:', data);
            setCategories(data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    // Fetch subcategories based on selected category
    const fetchSubcategories = async (categoryId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/categories/${categoryId}/subcategories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            console.log('Subcategories fetched:', data);
            setSubcategories(data);
            setSelectedSubSubcategory(null); // Clear sub-subcategory when switching subcategories
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
    };

    // Fetch sub-subcategories based on selected subcategory
    const fetchSubSubcategories = async (subcategoryId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/subcategories/${subcategoryId}/sub-subcategories`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            console.log('Sub-subcategories fetched:', data);
            setSubSubcategories(data);
        } catch (error) {
            console.error("Error fetching sub-subcategories:", error);
        }
    };

    // Fetch manual titles for the selected sub-subcategory
    const fetchManualTitles = async (subSubcategoryId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/${subSubcategoryId}`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            setManualTitles(data);
            setSelectedManual(null); // Clear selected manual when switching subcategories
            setManualContent(''); // Clear manual content when switching subcategories
        } catch (error) {
            console.error('Error fetching manual titles:', error);
        }
    };

    // Fetch manual content based on manual ID
    const fetchManualContent = async (manualId) => {
        try {
            const apiUrl = `${process.env.REACT_APP_SITE_URL}/oc/manuals/${selectedSubSubcategory?.id}`;
            const response = await fetch(apiUrl);
            const data = await response.json();

            console.log("Fetched data:", data);

            if (!Array.isArray(data) || data.length === 0) {
                throw new Error('No manuals found for this subcategory');
            }

            const manual = data.find(item => item.id === manualId);
            console.log("Selected manual:", manual);

            if (!manual || !manual.content) {
                throw new Error('Manual content not found');
            }

            setManualContent(manual.content || ''); // Set the content
            setSelectedManual(manual); // Set the selected manual for breadcrumb
        } catch (error) {
            console.error('Error fetching manual content:', error.message);
        }
    };

    // Handle category click
    const handleCategoryClick = async (category) => {
        setSelectedCategory(category);
        setSelectedSubcategory(null);
        setSelectedSubSubcategory(null);
        setManualContent('');
        setManualTitles([]);
        setSelectedManual(null);
        await fetchSubcategories(category.id);
    };

    // Handle subcategory click
    const handleSubcategoryClick = async (subcategory) => {
        setSelectedSubcategory(subcategory);
        setManualContent('');
        await fetchSubSubcategories(subcategory.id); // Fetch sub-subcategories when subcategory is selected
    };

    // Handle sub-subcategory click
    const handleSubSubcategoryClick = async (subSubcategory) => {
        setSelectedSubSubcategory(subSubcategory);
        await fetchManualTitles(subSubcategory.id); // Fetch manuals based on the sub-subcategory selected
    };

    // Handle manual click (fetch content)
    const handleManualClick = async (manual) => {
        await fetchManualContent(manual.id);
    };

    // Clear manual content (when clicking the breadcrumb to go back)
    const clearManualContent = () => {
        setManualContent('');
        setSelectedManual(null);
    };

    const toggleAdminPanel = () => {
        setShowManualsModal(!showManualsModal);
    };

    // Render manual content
    const renderManualContent = () => (
        <div className="manual-content">
            {manualContent ? (
                <div>
                    <h3>{selectedManual?.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: manualContent }} />
                </div>
            ) : (
                <p>No manuals available for this subcategory.</p>
            )}
        </div>
    );

    useEffect(() => {
        console.log('Selected category:', selectedCategory);
        console.log('Subcategories:', subcategories);
    }, [subcategories, selectedCategory]);

    return (
        <div>
            <h2>Manuals</h2>

            {/* Main Layout */}
            <div className="manuals-center-layout">
                {/* Sidebar Menu */}
                <div className="manuals-center-sidebar">
                    <div className="manuals-center-search">
                        <input
                            type="text"
                            placeholder="Search all manuals..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <h3>Categories</h3>
                    {categories.length > 0 ? (
                        categories
                            .filter(cat => cat.title.toLowerCase().includes(searchTerm.toLowerCase()))
                            .map(category => (
                                <div key={category.id} className="manuals-center-category">
                                    <div
                                        className="manuals-center-category-title"
                                        onClick={() => handleCategoryClick(category)}
                                    >
                                        {category.title}
                                    </div>
                                </div>
                            ))
                    ) : (
                        <p>No categories available</p>
                    )}

                    {isAdmin && (
                        <div className="manuals-center-admin">
                            <button onClick={toggleAdminPanel}>
                                {showManualsModal ? 'Close Admin Panel' : 'Open Admin Panel'}
                            </button>

                            {showManualsModal && <ManualsModal onClose={toggleAdminPanel} />}
                        </div>
                    )}
                </div>

                {/* Content Area */}
                <div className="manuals-center-content">
                    {/* Breadcrumb Navigation */}
                    <div className="manuals-center-breadcrumb">
                        <span onClick={() => { setSelectedCategory(null); clearManualContent(); }} className="breadcrumb-link">
                            Manuals
                        </span>
                        {selectedCategory && (
                            <>
                                {' > '}
                                <span onClick={() => { setSelectedSubcategory(null); clearManualContent(); }} className="breadcrumb-link">
                                    {selectedCategory.title}
                                </span>
                            </>
                        )}
                        {selectedSubcategory && (
                            <>
                                {' > '}
                                <span onClick={() => { setSelectedSubSubcategory(null); clearManualContent(); }} className="breadcrumb-link">
                                    {selectedSubcategory.title}
                                </span>
                            </>
                        )}
                        {selectedSubSubcategory && (
                            <>
                                {' > '}
                                <span className="breadcrumb-link">
                                    {selectedSubSubcategory.title}
                                </span>
                            </>
                        )}
                        {selectedManual && (
                            <>
                                {' > '}
                                <span className="breadcrumb-link">{selectedManual.title}</span>
                            </>
                        )}
                    </div>

                    {/* Subcategories */}
                    {selectedCategory && !selectedSubcategory && subcategories.length > 0 && (
                        <div className="manuals-center-box-layout">
                            {subcategories.map(subcategory => (
                                <div
                                    key={subcategory.id}
                                    className="manuals-center-box"
                                    onClick={() => handleSubcategoryClick(subcategory)}
                                >
                                    {subcategory.title}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Sub-Subcategories */}
                    {selectedSubcategory && !selectedSubSubcategory && subSubcategories.length > 0 && (
                        <div className="manuals-center-box-layout">

                            {subSubcategories.map(subSubcategory => (
                                <div
                                    key={subSubcategory.id}
                                    className="manuals-center-box"
                                    onClick={() => handleSubSubcategoryClick(subSubcategory)}
                                >
                                    {subSubcategory.title}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Manual Titles List */}
                    {manualTitles.length > 0 && !selectedManual && (
                        <div className="manuals-title-list">
                            <h3>Manuals</h3>
                            {manualTitles.map(manual => (
                                <div
                                    key={manual.id}
                                    className="manual-title"
                                    onClick={() => handleManualClick(manual)}
                                >
                                    {manual.title}
                                </div>
                            ))}
                        </div>
                    )}

                    {/* Manual Content Section */}
                    {selectedManual && (
                        <div className="manual-details">
                            {renderManualContent()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
