import React from 'react';

const ServiceStatus = React.memo(({ overrideDates }) => {
  const isOpen = (serviceType) => {
    const now = new Date();
    const day = now.getDay();
    const hour = now.getHours();
    const today = now.toISOString().split('T')[0];
    
    if (serviceType === 'AUTORESPONDER') {
      return true;
    }
    if (overrideDates.includes(today)) {
      return false;
    }
    if (serviceType === 'FILESERVICE') {
      if (day >= 1 && day <= 5 && hour >= 9 && hour < 21) {
        return true;
      } else if ((day === 6 || day === 0) && hour >= 10 && hour < 14) {
        return true;
      }
    }
    return false;
  };

  const timeUntilClosing = (serviceType) => {
    const now = new Date();
    const today = now.toISOString().split('T')[0];
    if (overrideDates.includes(today)) {
      return null;
    }

    let closingTime;
    if (serviceType === 'FILESERVICE') {
      const day = now.getDay();
      if (day >= 1 && day <= 5) {
        closingTime = new Date(now);
        closingTime.setHours(21, 0, 0, 0);
      } else if (day === 6 || day === 0) {
        closingTime = new Date(now);
        closingTime.setHours(14, 0, 0, 0);
      }
    } else {
      return null;
    }

    const diff = closingTime - now;
    if (diff <= 0) return null;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if (hours < 2) {
      return `(${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''} until closing)`;
    }
    return null;
  };

  const nuh = new Date().toLocaleTimeString('no-NO', { timeZone: 'Europe/Oslo' });

  return (
    <div className="all-column">
      <div>
        
        FILESERVICE STATUS: <span className={isOpen('FILESERVICE') ? 'open' : 'closed'}></span><br />
        <span>Mon-Fri: 09:00-21:00</span><br />
        <span>Sat-Sun: 10:00-14:00</span><br />
        {timeUntilClosing('FILESERVICE') && (
          <span>{timeUntilClosing('FILESERVICE')}</span>
        )}
      </div>
      <div>
        AUTORESPONDER: <span className={isOpen('AUTORESPONDER') ? 'open' : 'closed'}></span><br />
        <span>Mon-Sun: 24/7</span><br />
        <span>Only for Autotuner-uploads</span><br />

      </div>
    </div>
  );
});

export default ServiceStatus;