import React, { useState, useEffect, useRef } from 'react';

const FileDetailsID = ({
  fileDetails,
  setFileDetails, // Sørg for at denne er sendt inn som en prop fra parent
  isAdmin,
  isTuner,
  userProfile,

  buttonClass,

  buttonText,
  dealerStatusClass,
  dealerStatusText,

  transmissionOptions,
  fuelOptions,
  handleWipChange  // Add this to the destructured props
}) => {
  const [isEditRegModalVisible, setIsEditRegModalVisible] = useState(false);
  const [editRegValue, setEditRegValue] = useState(fileDetails.regnum);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copySuccess2, setCopySuccess2] = useState(false);
  const [logs, setLogs] = useState([]);  // Store logs here
  const [loadingLogs, setLoadingLogs] = useState(false);  // Loading state
  const EditRegModal = ({ visible, onClose, value, onChange, onSave }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (visible) {
        inputRef.current.focus();
      }
    }, [visible]);

    if (!visible) return null;

    return (
      <div className="editprofilemodal-background">
        <div className="editprofilemodal-content" onClick={(e) => e.stopPropagation()}>
          <h3>Edit Licenseplate of ID: {fileDetails.id}</h3>
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={onChange}
          />
          <button className="confirm-button" onClick={onSave}>Save</button>
          <button className="decline-button" onClick={onClose}>Cancel</button>
        </div>
      </div>
    );
  };

  const sendNotification = async (userId, title, body, data = {}) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userProfile.token}`
        },
        body: JSON.stringify({ userId, title, body, data })
      });
      if (!response.ok) {
        throw new Error('Failed to send notification');
      }
      const result = await response.json();
     // console.log('Notification sent:', result);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  // Funksjon for å åpne modal og sette registreringsnummeret
  const handleTextClick = () => {
    setEditRegValue(fileDetails.regnum);
    setIsEditRegModalVisible(true);
  };

  // Funksjon for å håndtere input-endring
  const handleInputChange = (e) => {
    setEditRegValue(e.target.value);
  };

  // Funksjon for å lagre oppdatering av registreringsnummer
  const handleSave = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateRegnum`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ fileId: fileDetails.id, regnum: editRegValue })
      });

      if (response.ok) {
        setFileDetails(prevDetails => ({ ...prevDetails, regnum: editRegValue }));
        setIsEditRegModalVisible(false);
      } else {
        throw new Error('Failed to update license plate');
      }
    } catch (error) {
      console.error('Error updating license plate:', error);
    }
  };

  const changeStatusDirectly = async (fileId, status) => {
    // console.log(`Starting to update file status. File ID: ${fileId}, New Status: ${status}`);

    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${fileId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status,
          tuner: isTuner ? 'Tuner' : 'Admin',  // Hvis du vil ha 'tuner'-info
          dealercheck: 1 // Setter dealercheck til 1
        }),
      });

      // console.log(`Response status: ${response.status}`);

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error response from server:', errorData);
        throw new Error(`Failed to update file status. Server responded with status: ${response.status}`);
      }

      if (status == 3) {
        sendNotification(
          fileDetails.mid,
          'Optifiles',
          'ID ' + fileId + ': ON HOLD',
          { messageId: '123', sender: 'John Doe' }
        );
      }
      setFileDetails(prevDetails => ({ ...prevDetails, status }));
      // console.log('File status and dealercheck updated successfully.');
    } catch (error) {
      console.error('Error updating file status and dealercheck:', error);
      alert('Failed to update file status. Please try again.');
    }
  };

  const handleCopyToClipboard = (e) => {
    e.stopPropagation(); // Stopper klikket fra å utløse andre onClick-hendelser
    navigator.clipboard.writeText(fileDetails.regnum).then(() => {
      setCopySuccess2(true);
      setTimeout(() => setCopySuccess2(false), 2000); // Skjuler notifikasjonen etter 2 sekunder
    }).catch(err => {
      console.error('Failed to copy license plate: ', err);
    });
  };

  const handleCopyId = () => {
    const textToCopy = `${fileDetails.id} - ${fileDetails.vehicleDescription}`;

    navigator.clipboard.writeText(textToCopy)
        .then(() => {
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 1000);
        })
        .catch(err => {
            console.error('Could not copy text: ', err);
        });
};

const fetchLogs = async () => {
  setLoadingLogs(true);
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/fetch-logs/${fileDetails.id}`);
    const data = await response.json();
    if (response.ok) {
      setLogs(data.logs);  // Save the fetched logs to state
    } else {
      console.error('Error fetching logs:', data.message);
    }
  } catch (error) {
    console.error('Error fetching logs:', error);
  } finally {
    setLoadingLogs(false);
  }
};

useEffect(() => {
  fetchLogs();  // Fetch logs when the component is mounted
}, [fileDetails.id]);

const renderLogs = () => {
  if (loadingLogs) {
    return <p>Loading logs...</p>;
  }

  if (logs.length === 0) {
    return <p>No logs found.</p>;
  }

  // Sort logs by date in descending order
  const sortedLogs = logs.sort((a, b) => new Date(b.date) - new Date(a.date));

  // Show only the last 3 logs (most recent)
  const latestLogs = sortedLogs.slice(0, 3);

  // Format date as "27 Sep 2024 22:03:04"
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0'); // 27
    const month = date.toLocaleString('default', { month: 'short' }); // Sep
    const year = date.getFullYear(); // 2024
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }); // 22:03:04

    return `${day} ${month} ${year} ${time}`;
  };

  return (
    <div className="logs-entrycontainer">
      {latestLogs.map((log, index) => (
        <div key={index} className="log-entry">
          {log.tuner} {log.action} {formatDate(log.date)}
        </div>
      ))}
    </div>
  );
};







  const markAsRead = async (fileId) => {
    setFileDetails(prevDetails => ({ ...prevDetails, message: 0 }));
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/markAsRead', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({ fileId }),
      });

      if (!response.ok) {
        console.error('Failed to mark as read');
        setFileDetails(prevDetails => ({ ...prevDetails, message: 1 }));
        return;
      }
    } catch (error) {
      console.error('Error marking as read:', error);
      setFileDetails(prevDetails => ({ ...prevDetails, message: 1 }));
    }
  };

  return (
    <div>
      {/* <p className="detailtextmedium"><b>{fileDetails.vehicleDescription}</b></p> */}


      {(isAdmin || isTuner) && (
        <>
          <button className={buttonClass} onClick={() => markAsRead(fileDetails.id)}>
            {buttonText}
          </button>
          {isAdmin && (
              <button
                type="button"
                onClick={handleWipChange}  // You may need to pass this function as a prop
                className={`wip-button ${fileDetails.wip ? 'active' : ''}`}
              >
                Work In Progress
              </button>
            )}
          <div className="wip-toggle">

            <button
              className={`sent-button ${fileDetails.status === 0 ? 'active-status-button' : ''}`}
              onClick={() => changeStatusDirectly(fileDetails.id, 0)}
            >
              Sent
            </button>
            <button
              className={`ip-button ${fileDetails.status === 1 ? 'active-status-button' : ''}`}
              onClick={() => changeStatusDirectly(fileDetails.id, 1)}
            >
              In P
            </button>
            <button
              className={`hold-button ${fileDetails.status === 3 ? 'active-status-button' : ''}`}
              onClick={() => changeStatusDirectly(fileDetails.id, 3)}
            >
              Hold
            </button>
            <button
              className={`donedeal-button ${fileDetails.status === 2 ? 'done-status-button' : ''}`}
              onClick={() => changeStatusDirectly(fileDetails.id, 2)}
            >
              Ready
            </button>
<br></br>
            <p className="detailtext-inline">
              <b>Dealerstatus: </b>
              <span className={dealerStatusClass}>{dealerStatusText}</span>
            </p>
          </div>
        </>
      )}

      <p className="detailtext" onClick={handleTextClick}>
        <b>License plate:</b> {fileDetails.regnum}
        {isAdmin && (
          <>
            <button className="copy-button2" onClick={handleCopyToClipboard}>Copy</button>
            {copySuccess2 && <div className="copy-notification2">Licenseplate copied!</div>}
          </>
        )}
      </p>

      <EditRegModal
        visible={isEditRegModalVisible}
        onClose={() => setIsEditRegModalVisible(false)}
        value={editRegValue}
        onChange={handleInputChange}
        onSave={handleSave}
      />

      <p className="detailtext"><b>Transmission:</b> {transmissionOptions[fileDetails.ttype]}</p>
      <p className="detailtext"><b>Fuel:</b> {fuelOptions[fileDetails.fuel_type]}</p>


                  {(isAdmin || isTuner) && (
                    <>
                            {renderLogs()}
                    </>
                  )}


    </div>
  );
};

export default FileDetailsID;
