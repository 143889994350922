import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import alert from '../assets/alert.gif';
import { UserContext } from '../context/UserContext';

export default function FilePortal() {
  const [vehicleData, setVehicleData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const { authToken } = useAuth();
  const [selectedDealer, setSelectedDealer] = useState('');
  const [userStats, setUserStats] = useState({
    todayFiles: 0,
    weeklyFiles: 0,
    monthlyFiles: 0,
    yearlyFiles: 0
  });

  // Definer fetchVehicleData som en useCallback for å unngå unødvendige funksjonsopprettelser
  const fetchVehicleData = useCallback(async () => {
    try {
      let url = `${process.env.REACT_APP_SITE_URL}/uploads?page=${page}&limit=500&searchText=${encodeURIComponent(searchText)}`;
      if (selectedDealer) {
        url += `&dealerId=${selectedDealer}`;
      }
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const newData = await response.json();
      setVehicleData((prev) => (page === 1 ? newData : [...prev, ...newData]));
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    }
  }, [authToken, page, searchText, selectedDealer]);

  // Kjør fetchUserStats én gang ved komponentens initielle rendering
  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/stats`);
        const data = await response.json();
        setUserStats(data);
      } catch (error) {
        console.error('Error fetching total stats:', error);
      }
    };
    fetchUserStats();
  }, []);

  // Automatisk oppdatering hvert 20. sekund
  useEffect(() => {
    fetchVehicleData(); // Initial kall

    const interval = setInterval(() => {
      fetchVehicleData();
    }, 10000);

    return () => clearInterval(interval); // Rydder opp intervallet ved avmontering
  }, [fetchVehicleData]);

  const getTuningTypeLabel = (tuningTypeId) => {
    const tuningTypes = {
      '1': { label: 'E', className: 'eco' },
      '3': { label: 'O', className: 'opti' },
      '4': { label: 'P', className: 'power' },
      '5': { label: 'S', className: 'stock' },
      '6': { label: 'X', className: 'extreme' }
    };
    return tuningTypes[tuningTypeId] || { label: 'Ukjent', className: '' };
  };

  const formatDateTime = (dateTimeString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateTimeString));
  };

  const sortedVehicleData = [...vehicleData].sort((a, b) => {
    const isPriorityA = a.status !== 2;
    const isPriorityB = b.status !== 2 && b.message != null;
    if (isPriorityA && !isPriorityB) return -1;
    if (!isPriorityA && isPriorityB) return 1;
    return 0;
  });

  return (
    <div className="container">
      <div className="samlecontainer">
        <div className="news-column">
          <div className="box-container">
            <div className="box">Today<br />{userStats.todayFiles || 0}</div>
            <div className="box">Weekly<br />{userStats.weeklyFiles || 0}</div>
            <div className="box">Monthly<br />{userStats.monthlyFiles || 0}</div>
            <div className="box">Yearly<br />{userStats.yearlyFiles || 0}</div>
          </div>
        </div>
      </div>
      <div className="files-section">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Vehicle</th>
              <th>Registration</th>
              <th>Dealer</th>
              <th></th>
              <th></th>
              <th>Tuner</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {sortedVehicleData.map((vehicle, index) => {
              const showAlert = vehicle.message === 1 && vehicle.tunercheck === 1;

              return (
                <tr key={index} className={`status-${vehicle.status}`}>
                  <td>{vehicle.id}</td>
                  <td>{vehicle.vehicleDescription || 'Ukjent Kjøretøy'}</td>
                  <td>{vehicle.regnum}</td>
                  <td>{vehicle.dealerName || 'Ukjent Dealer'}</td>
                  <td>{showAlert ? <img src={alert} alt="Alert" className="alert" /> : null}</td>
                  <td>
                    {vehicle.status === 0 && (
                      <>
                        <span className={`tuningType ${getTuningTypeLabel(vehicle.tuning_type).className}`}>
                          {getTuningTypeLabel(vehicle.tuning_type).label}
                        </span>
                        {vehicle.egr !== 0 && <p className="file-option-small egr">E</p>}
                        {vehicle.dpf !== 0 && <p className="file-option-small dpf">D</p>}
                        {vehicle.adblue !== 0 && <p className="file-option-small adblue">A</p>}
                        {vehicle.nsl !== 0 && <p className="file-option-small nsl">NSL</p>}
                        {vehicle.o2 !== 0 && <p className="file-option-small o2">O2</p>}
                        {vehicle.epa !== 0 && <p className="file-option-small epa">EPA</p>}
                        {vehicle.hybridturbo !== 0 && <p className="file-option hybrid-turbo">HT</p>}
                        {vehicle.gearbox !== 0 && <p className="file-option gearbox">GT</p>}
                        {vehicle.coldstart !== 0 && <p className="file-option cold-start">CS</p>}
                        {vehicle.startstop !== 0 && <p className="file-option start-stop">SS</p>}
                        {vehicle.popsbangs !== 0 && <p className="file-option pops-bangs">PB</p>}
                        {vehicle.burbles !== 0 && <p className="file-option burbles">BU</p>}
                        {vehicle.swirlflaps !== 0 && <p className="file-option swirl-flaps">SW</p>}
                        {vehicle.tva !== 0 && <p className="file-option tva">TVA</p>}
                      </>
                    )}
                  </td>
                  <td>{vehicle.tuner}</td>
                  <td>{formatDateTime(vehicle.created)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
