import React, { useEffect, useRef, useState } from 'react';

export default function Dropbox() {
    const embedContainerRef = useRef(null); // Reference for the embed container
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        const container = embedContainerRef.current; // Copy ref to local variable
        let embed = null; // Store embed instance

        // Dynamically load the Dropbox Drop-ins script
        const script = document.createElement('script');
        script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
        script.id = 'dropboxjs';
        script.setAttribute('data-app-key', 'oarwzxmdki584d4'); // Replace with your actual app key
        document.body.appendChild(script);

        script.onload = () => {
            console.log('Dropbox script loaded');
            if (window.Dropbox && typeof window.Dropbox.embed === 'function') {
                console.log('Dropbox.embed is available');
                const options = {
                    link: 'https://www.dropbox.com/sh/h43ouuhd26z8yge/AAAowUKCsNf5DQRLAgYZYNPQa?dl=0',
                    folder: {
                        view: 'list', // or 'grid'
                        headerSize: 'normal', // or 'small'
                    },
                    width: '100%',
                    height: '100%',
                    
                };
        
                // Embed the Dropbox folder
                embed = window.Dropbox.embed(options, embedContainerRef.current);
                console.log('Dropbox embed initialized', embed);

                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            
            } else {
                console.error('Dropbox.embed is not available');
                setIsLoading(false);
            }
        };

        return () => {
            // Cleanup script and iframe on unmount
            if (embed) {
                window.Dropbox.unmount(embed);
            }

            // Remove script
            const existingScript = document.getElementById('dropboxjs');
            if (existingScript) {
                existingScript.remove();
            }

            // Remove embed container content
            if (container) {
                container.innerHTML = '';
            }
        };
    }, []); // Runs only once

    return (
        <div>
            
            <div ref={embedContainerRef} id="dropbox-container">{isLoading && <p>Loading Dropbox...</p>}</div>
        </div>
    );
}
