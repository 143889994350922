import React, { useState, useEffect } from 'react';
import moment from 'moment';
import comment from '../assets/comment.png';

export default function Customers({ username, userProfile }) {
    const [tasks, setTasks] = useState([]);
    const [currentDate, setCurrentDate] = useState(moment()); // Dagens dato
    const [weekTasks, setWeekTasks] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [commentCounts, setCommentCounts] = useState({});
    const [commentChecks, setCommentChecks] = useState({}); // Ny state for commentcheck

    useEffect(() => {
        fetchTasks();
    }, []);

    useEffect(() => {
    }, [userProfile]);



useEffect(() => {
    const fetchCommentCounts = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/admin/task-comment-count`);
            const data = await response.json();

            const counts = {};
            const checks = {};

            data.forEach(item => {
                counts[item.task_id] = item.comment_count;
                checks[item.task_id] = item.commentcheck === 1; // Konverter til boolean for enklere bruk
            });

            setCommentCounts(counts);
            setCommentChecks(checks);
        } catch (error) {
            console.error('Error fetching comment counts:', error);
        }
    };

    fetchCommentCounts();
}, []);


      
    useEffect(() => {
        const startOfWeek = currentDate.clone().startOf('week').add(1, 'day'); // Mandag
        const endOfWeek = currentDate.clone().endOf('week'); // Søndag

        const filteredTasks = tasks.filter(task => {
            const taskStart = moment(task.datetime);
            const taskEnd = task.enddatetime ? moment(task.enddatetime) : taskStart;
            return !task.done && taskStart.isBefore(endOfWeek) && taskEnd.isAfter(startOfWeek);
        });

        const tasksForWeek = filteredTasks
            .sort((a, b) => new Date(a.datetime) - new Date(b.datetime)) // Sorter kronologisk
            .map(task => {
                const taskStart = moment(task.datetime);
                const taskEnd = task.enddatetime ? moment(task.enddatetime) : taskStart;
                const startDayIndex = Math.max(0, taskStart.diff(startOfWeek, 'days'));
                const endDayIndex = Math.min(6, taskEnd.diff(startOfWeek, 'days'));

                return {
                    ...task,
                    gridColumn: `${startDayIndex + 1} / ${endDayIndex + 2}`,
                };
            });

        setWeekTasks(tasksForWeek);
    }, [currentDate, tasks]);

    const fetchTasks = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/admin/tasks-not-done');
            const data = await response.json();
            setTasks(data.filter(task => task.done === 0));
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const changeWeek = (direction) => {
        setCurrentDate(currentDate.clone().add(direction, 'week'));
    };

    const formatTime = (dateTimeString) => {
        const options = { hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC' };
        return new Date(dateTimeString).toLocaleTimeString('no-NO', options);
    };
    

    const handleTaskClick = (task) => {
        setSelectedTask(task);
        fetchComments(task.id);
    };

    const handleUpdateOOH = async (taskId, status) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${taskId}/ooh-status`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ooh: status, oohtuner: username }),
            });

            if (response.ok) {
                setTasks(prevTasks =>
                    prevTasks.map(task =>
                        task.id === taskId ? { ...task, ooh: status } : task
                    )
                );
            } else {
                console.error('Error updating OOH status');
            }
        } catch (error) {
            console.error('Error updating OOH status:', error);
        }
    };

    const fetchComments = async (taskId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/taskcom/${taskId}`);
            const data = await response.json();
            setComments(data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const sendNotification = async (userId, title, body, data = {}) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${userProfile.token}`
            },
            body: JSON.stringify({ userId, title, body, data })
          });
          if (!response.ok) {
            throw new Error('Failed to send notification');
          }
          const result = await response.json();
        } catch (error) {
          console.error('Error sending notification:', error);
        }
      };
    
      const markAsRead = async (taskId) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${taskId}/mark-as-read`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
          });
      
          if (!response.ok) {
            throw new Error('Failed to mark task as read');
          }
      
          const data = await response.json();
          console.log('Task marked as read:', data);
      
          // Oppdater state for å reflektere endringen
          setCommentChecks((prev) => ({ ...prev, [taskId]: false }));
        } catch (error) {
          console.error('Error marking task as read:', error);
        }
      };
      
    
      const addComment = async () => {
        if (!newComment.trim() || !selectedTask) return;
    
        try {
            // Send varsling før lagring av kommentaren
            await sendNotification(
                selectedTask.did, // Mottakerens ID
                `Optifiles Booking:`, // Tittel på varsling
                `${selectedTask.vehicle}: ${newComment}`, // Innholdet i varslingen
                {
                    messageId: `${selectedTask.id}`, // Ekstra data
                    sender: userProfile.name // Senderens navn
                }
            );
    
            console.log('Notification sent successfully');
    
            // Lagring av kommentaren
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/taskcom-un-check`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    taskid: selectedTask.id,
                    user: username || 'Unknown User',
                    message: newComment,
                }),
            });
    
            if (response.ok) {
                const addedComment = await response.json();
                setComments(prev => [...prev, { ...addedComment, date: new Date() }]);
                setNewComment('');
            }
        } catch (error) {
            console.error('Error adding comment or sending notification:', error);
        }
    };
    
    
    
    
    

    return (
        <div className="week-planner">
            <div className="week-header">
                <div className="week-info">
                    <h3>{currentDate.format('MMMM YYYY')}</h3>
                    <h4>Week {currentDate.isoWeek()}</h4>
                </div>
                <div className="week-navigation">
                    <button onClick={() => changeWeek(-1)}>◀ Previous</button>
                    <button onClick={() => changeWeek(1)}>Next ▶</button>
                </div>
            </div>
            <div className="week-grid">
            {[...Array(7)].map((_, dayIndex) => {
                    const date = currentDate.clone().startOf('week').add(dayIndex + 1, 'days');
                    const tasksForDay = weekTasks.filter(task => {
                        const taskDate = moment(task.datetime);
                        return taskDate.isoWeekday() === dayIndex + 1; // Mandag til Søndag
                    });

                    // Sorter oppgavene for dagen
                    tasksForDay.sort((a, b) => moment(a.datetime).diff(moment(b.datetime)));

                    return (
                        <div className="day-column" key={dayIndex}>
                            <div className="day-header">
                                <div className="day-name">{date.format('dddd')} {date.format('DD')}</div>
                            </div>
                            {tasksForDay.map((task, index) => (
                                <div
                                    key={task.id}
                                    className="task-item"
                                    style={{
                                        gridRow: `${index + 1} / span 1`,
                                    }}
                                    onClick={() => handleTaskClick(task)} // Legger til klikkhåndtering
                                >
                                    <div className="task-details">
                                <span className="dealer-name">{task.dealer_name}</span>
                                <span>{task.vehicle}</span>
                                <span className="task-miniinfo">{task.info}</span>
                                <div className="task-meta">
                                    <div className="comment-container">
                                        <img src={comment} alt="comment" className="comment-icon" />
                                        <span className="commentcount">
                                {commentCounts[task.id] || 0}
                                {commentChecks[task.id] && <span className="new-taskcomment">(NEW)</span>}
                            </span>



                                    </div>
                                    <div className="time-wrapper">
                                        <span className="time">{formatTime(task.datetime)}</span>
                                    </div>
                                    </div>


                              </div>
                              
                                    {task.ooh === 1 && (
                                        <div className="ooh-section">
                                            <p>Out of Hours Request:</p>
                                            <div className="ooh-actions">
                                                <button onClick={() => handleUpdateOOH(task.id, 2)}>Accept</button>
                                                <button onClick={() => handleUpdateOOH(task.id, 3)}>Decline</button>
                                            </div>
                                        </div>
                                    )}
                                    {task.ooh === 2 && <p className="ooh-accepted">OOH Accepted</p>}
                                    {task.ooh === 3 && <p className="ooh-declined">OOH Declined</p>}

                                        </div>
                                    ))}
                        </div>
                    );
                })}

            </div>

            {selectedTask && (
    <div className="taskmodal-background">
        <div className="taskmodal-content">
            <div className="task-info">
                <p>
                    <strong>Vehicle:</strong> {selectedTask.vehicle}<br />
                    {selectedTask.info}
                </p>
            </div>

            {/* Seksjon for visning av eksisterende kommentarer */}
            <div className="admincomments-section">
                {comments.length > 0 ? (
                    comments.map((comment, index) => (
                        <div key={index} className="admincomment-item">
                            <p><strong>{comment.user}:</strong> {comment.message}</p>
                            <p className="comment-date">{moment(comment.date).format('DD.MM.YYYY HH:mm')}</p>
                        </div>
                    ))
                ) : (
                    <p className="no-comments">No comments yet.</p>
                )}
            </div>

            {/* Seksjon for å legge til ny kommentar */}
            <div className="new-comment-section">
                <textarea
                    className="new-comment"
                    placeholder="Write a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                ></textarea>
                <button className="btn btn-primary" onClick={addComment}>Add Comment</button>
                <button className="btn btn-secondary" onClick={() => setSelectedTask(null)}>Close</button>
                <button className="btn btn-success" onClick={() => markAsRead(selectedTask.id)}>Mark as Read</button>
            </div>
        </div>
    </div>
)}


        </div>
    );
}
